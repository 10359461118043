import React from "react";
import { birthdayData } from "../../utils/data/dummyData";
import BirthdayCard from "../cards/BirthdayCard";

const UpcomingBirthdays = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of the day

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isWithinDays = (date, days) => {
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + days);
    return date > today && date <= targetDate;
  };

  const isWithinMonth = (date) => {
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return date > today && date <= nextMonth;
  };

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  // Filter birthdays into categories
  const todayBirthdays = birthdayData.filter((birthday) =>
    isSameDay(new Date(birthday.date), today)
  );

  const thisWeekBirthdays = birthdayData.filter((birthday) =>
    !isSameDay(new Date(birthday.date), today) && isWithinDays(new Date(birthday.date), 7)
  );

  const thisMonthBirthdays = birthdayData.filter((birthday) =>
    !isSameDay(new Date(birthday.date), today) &&
    !isWithinDays(new Date(birthday.date), 7) &&
    isWithinMonth(new Date(birthday.date))
  );

  return (
    <>
    <div className="flex flex-col  pb-4 gap-4">

        <div className='text-dark font-bold text-h6 flex w-full justify-between items-center '>
          <span>Upcoming Birthdays</span><span className="text-base2 font-normal text-[#333333]">View all</span> </div>
      <div className='bg-white pt-4 pb-4 rounded-[5px] border'>

        <div>
          <h5 className='font-semibold px-4 text-gray-600 border-b  pb-4'>
            Today
          </h5>
          {todayBirthdays.length ? (
            todayBirthdays.map((birthday) => (
              
              <BirthdayCard key={birthday.id} avatar={birthday.profileImage} name={birthday.name} date={formatDate(new Date(birthday.date))} />
            ))
          ) : (
            <p>No birthdays today.</p>
          )}
        </div>

        <div>
          <h5 className='font-semibold text-gray-600 border py-4 px-4'>This Week</h5>
          {thisWeekBirthdays.length ? (
            thisWeekBirthdays.map((birthday) => (
              <BirthdayCard key={birthday.id} avatar={birthday.profileImage} name={birthday.name} date={formatDate(new Date(birthday.date))} />
            ))
          ) : (
            <p>No birthdays this week.</p>
          )}
        </div>

        <div>
          <h5 className='font-semibold text-gray-600 border py-4 px-4'>This Month</h5>
          {thisMonthBirthdays.length ? (
            thisMonthBirthdays.map((birthday) => (
              <BirthdayCard key={birthday.id} avatar={birthday.profileImage} name={birthday.name} date={formatDate(new Date(birthday.date))} />
            ))
          ) : (
            <p>No birthdays this month.</p>
          )}
        </div>
      </div>
          </div>
    </>
  );
};

export default UpcomingBirthdays;