import React from 'react';
import { LoadingSpinner } from './ui';
import useAuthStore from '../store/authStore';

const AuthProvider = ({ children }) => {
  const { user } = useAuthStore();

  if (user === undefined) {
    return <LoadingSpinner />;
  }

  return children;
};

export default AuthProvider;

