import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const RichTextEditor = ({ value, onChange }) => {
  return (
    <div className="">
      <Editor
        apiKey="pavc0fmrsur7f3i11rrn5ssabkaa714sbvgr73g2c4eqy6di"
        value={value}
        onEditorChange={(content) => onChange(content)}
        init={{
            automatic_uploads: true,
            image_title: true,
            file_picker_types: 'image',
            file_picker_callback : function(cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                    var file = this.files[0];
                    var reader = new FileReader();
                    reader.onload = function () {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                    }
                input.click();
            },


            images_file_types: 'jpg,jpeg,png,gif,svg',
            body_class:'wine_body',
            
          height: 380,
          menubar: false,
          statusbar: false,
          plugins: [
            'link', 'image', 'lists'
          ],
          toolbar: [
            {
              name: 'history',
              items: ['undo', 'redo']
            },
            {
                name: 'styles',
                items: [
                    'fontsize', 'forecolor' 
                    ] // This adds the text style selector
                
              },
            {
              name: 'formatting',
              items: ['formatselect']
            },
            {
              name: 'formatting',
              items: ['bold', 'italic', 'underline']
            },
            {
              name: 'alignment',
              items: ['alignleft', 'aligncenter', 'alignright']
            },
            {
              name: 'insert',
              items: ['link', 'image']
            }
          ],
          formats: {
            p: { block: 'p' },
            h1: { block: 'h1' },
            h2: { block: 'h2' },
            h3: { block: 'h3' }
          },
          font_size_formats: 'Normal=14px Heading6=16px Heading5=18px Heading4=20px Heading3=24px Heading2=28px Heading1=32px',
        //   font size background color


          content_style: `
        
            body {
              font-size: 14px;
              margin: 0;
              padding: 16px;
             font-family: 'Poppins', sans-serif !important;
              font-weight: 400 !important;
                line-height: 21px !important;
                color: #2D2D2D !important;
            }
          body .tox-editor-container .tox-tbtn--bespoke {
                    background-color: transparent !important;
                 
                  }
          `,
          skin: 'oxide',
          content_css: 'default',
          branding: false,
          elementpath: false,
          resize: false,
          toolbar_mode: 'wrap',
          toolbar_sticky: false,
          toolbar_location: 'top',

        }}
      />
    </div>
  );
};

export default RichTextEditor;



//pavc0fmrsur7f3i11rrn5ssabkaa714sbvgr73g2c4eqy6di

