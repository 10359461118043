import React from 'react';

const Table = ({ children }) => {
  return (
    <div className="space-y-4">
    <div className="overflow-x-auto border border-gray-200 rounded-md px-4 max-w-screen-xxl mt-2">
      <table className="w-full bg-white rounded-lg shadow-sm overflow-x-auto">
          {children}
        </table>
      </div>
    </div>
  );
};

export default Table;
