import React, { useState } from 'react'
import { Button, Container, Tabs } from '../../components/ui'
import { TiPlus } from 'react-icons/ti'
import useModals from '../../hooks/useModals'
import PaymentsCardList from './PaymentsCardList'

import useIntegrations from '../../hooks/useIntegrations'
import ShippingCardList from './ShippingCardList'
import ShippingModal from './Modals/ShippingModal'
import PaymentsModal from './Modals/PaymentsModal'


const IntergrationsPage = () => {

  const {isModalOpen , closeModal ,openModal } =useModals()

  const {data ,isPaymentGateways ,setIsPaymentGateways ,tabs} = useIntegrations()

  const [paymentData, setPaymentData] = useState(null)
  const [shippingData, setShippingData] = useState(null)


  const onCloseModal = (type) => {
    setPaymentData(null)
    setShippingData(null)
    closeModal(type)
  }

  const onEditPayment = (data) => {
    setPaymentData(data)
    openModal('paymentModal')
  }

  const onEditShipping = (data) => {
    setShippingData(data)
    openModal('shippingModal')
  }



  return (
    <Container type="flex" className="px-0 py-2 " size="full">
    <div className='flex pl-5 px-10 justify-between'>
      <h5 className="text-h5 font-bold ">Integration Page</h5>
      { isPaymentGateways ? (
           <Button aschild variant='default' onClick= {()=> {openModal('paymentModal')}} >
           <TiPlus />   Add Payment Processor
         </Button>
      ):
      (
        <Button aschild variant='default' onClick= {()=> {openModal('shippingModal')}} >
        <TiPlus />   Add Shipping Carrier
        </Button>
        )
      }
     
    </div>
    <Tabs tabs={tabs} activeTab={isPaymentGateways} onTabChange={setIsPaymentGateways} />

    {/* Manage Payments  and Shipping */}


    { isPaymentGateways ? (
      <PaymentsCardList data={data.integrations} onEditPayment={onEditPayment} />
    ) : (
      <ShippingCardList data={data.shippingcarries} onEditShipping={onEditShipping} />
    ) }



    <PaymentsModal
      isOpen={isModalOpen('paymentModal')}
      onClose={() => onCloseModal('paymentModal')}
      title="Add Payment Processor"
      actionLabel="Save"
      secondaryAction={() => onCloseModal('paymentModal')}
      secondaryActionLabel="Cancel"
      initialValues={paymentData}
    />

    <ShippingModal
    isOpen={isModalOpen('shippingModal')}
    onClose={ () => onCloseModal('shippingModal')}
    title='Add Shipping Carrier'
    size='large'
    actionLabel={'Save'}
    secondaryAction={() => onCloseModal('shippingModal')}
    secondaryActionLabel={'Cancel'}
    initialValues={shippingData}
    />

  </Container>
  )
}

export default IntergrationsPage
