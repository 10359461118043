import React from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import { Checkbox } from "../../ui";
import Table from "../../ui/table/Table";
import TableHeader from "../../ui/table/TableHeader";
import TableRow from "../../ui/table/TableRow";
import TableBody from "../../ui/table/TableBody";
import TableCell from "../../ui/table/TableCell";
import dummyUsersAccounts from "../../../utils/data/users";

const Step4SelectRecipients = () => {
  const { values, setFieldValue } = useFormikContext();

  const columns = ["Name", "Email", "Club"];

  const handleCheckboxChange = (userId, userEmail) => {
    const selectedUsers = values.selectedUsers.some((user) => user.id === userId)
      ? values.selectedUsers.filter((user) => user.id !== userId)
      : [...values.selectedUsers, { id: userId, email: userEmail }];
    setFieldValue("selectedUsers", selectedUsers);
  };

  return (
    <div className=" custom_body_input grid gap-[12px] w-full mx-auto select-none">
      <Table>
        <TableHeader columns={columns} hasActions />

        <TableBody>
          {dummyUsersAccounts.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <div className="w-full inline-flex gap-4">
                  <Checkbox
                    id={`user-${user.id}`}
                    checked={values.selectedUsers?.some((selectedUser) => selectedUser.id === user.id)}
                    onChange={() => handleCheckboxChange(user.id, user.email)}
                  />
                  {user.name}
                </div>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.club}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

// Validation schema
export const Step4SelectRecipientsValidation = Yup.object().shape({
  selectedUsers: Yup.array().min(1, "Select at least one Recipient"),
});

export default Step4SelectRecipients;