// dummmy data for integrations with an Image , title status location


export const Dummyintegrations = {
integrations : [
  {
    id : 1,
    image: "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "Stripe",
    status: "Active",
    location: "United States"
  },
  {
    id : 2,
    image : "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "Paypal",
    status: "Archive",
    location: "United States"
  },
  
  {
    id : 3,
    image: "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "Stripe",
    status: "Archive",
    location: "United States"
  },
  {
    id : 4 ,
    image : "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "Paypal",
    status: "Active",
    location: "United States"
  }
] ,

shippingcarries : [
  {
    id:42332,
    image: "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "DHL",
    status: "Active",
    location: "United States",
    licenceKey: ": 5741 2543 7981 4231"
  },
  {
    id:423 ,
    image : "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "Fedex",
    status: "Disabled",
    location: "United States",
    licenceKey: ": 5741 2543 7981 4231"

  },
  
  {
    id: 4232,
    image: "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "UPS",
    status: "Active",
    location: "United States",
    licenceKey: ": 5741 2543 7981 4231"

  },
  {
    id: 4234232,
    image : "https://cdn-icons-png.flaticon.com/512/732/732221.png",
    name: "USPS",
    status: "Active",
    location: "United States",
    licenceKey: ": 5741 2543 7981 4231"

  }
]


}