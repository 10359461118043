import React from 'react';

const Tabs = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="relative flex border-b justify-start">
      {tabs.map((tab) => (
        <span
          key={tab.label}
          onClick={() => onTabChange(tab.value)}
          className={`relative text-base2 font-medium md:cursor-pointer p-4 text-center w-fit min-w-[180px] ${
            activeTab === tab.value ? 'text-primary' : 'text-[#666666]'
          }`}
        >
          {tab.label}
          {activeTab === tab.value && (
            <div className="absolute bottom-[0px] left-0 right-0 h-[2px] bg-primary"></div>
          )}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
