import { toast } from 'react-hot-toast';

const useNotification = () => {
  const toastOptions = {
    duration: 4000,
    position: 'top-right',
    // open from the right side
    style: {
      color: '#fff',
      padding: '16px',
      borderRadius: '8px',
      fontSize: '14px',
      fontFamily: "Poppins, sans-serif",
      fontWeight : "400",
      maxWidth: '435px',
      

    },
  };

  const STATUSES = {
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading',
  }

  const showToast = (message, type = 'default') => {
    switch (type) {
      case 'success':
        return toast.success(message, {
          ...toastOptions,
          style: { ...toastOptions.style, background: '#008000' },
        });
      case 'error':
        return toast.error(message, {
          ...toastOptions,
          style: { ...toastOptions.style, background: '#71101A' },
        });
      case 'loading':
        return toast.loading(message, {
          ...toastOptions,
        });
      default:
        return toast(message, toastOptions);
    }
  };

  const updateToast = (toastId, message, type) => {
    toast.dismiss(toastId);
    return showToast(message, type);
  };

  return { showToast, updateToast , STATUSES};
};

export default useNotification;

