import React from "react";
import cn from "../../utils/cn";

// Input Component

const inputStyles = {
  base: "  flex h-[56px] w-full rounded-md border border-input bg-background  py-2  text-sm ring-offset-background  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  placeholder:text-base2 placeholder:text-secondary-grey placeholder:font-normal",
  sizes: {
    sm: "h-full max-h-[40px] min-h-[32px] px-3 py-2 text-sm",
    md: "h-full max-h-[48px] min-h-[40px] px-4 py-3 text-base",
    lg: "h-full max-h-[56px] min-h-[48px]  py-4 text-base",
  },
  spacings: {
    default: "",
    normal:"px-5",
    iterator: "px-0"
  }
};


 const Input = React.forwardRef(({ className,size="lg", type = "text", spacing="normal", ...props }, ref) => {

    // Disable onWheel scroll on input[type=number]
    if (type === "number") {
      const handleWheel = (event) => {
        event.target.blur();
      };
  
      return (
        <input
          type={type}
          className={cn(
            className,
            inputStyles.spacings[spacing],
            inputStyles.base,
            inputStyles.sizes[size],
          )}
          ref={ref}
          onWheel={handleWheel}
          {...props}
        />
      );
    }

    return (
      <input
        type={type}
        className={cn(
          className,
          inputStyles.spacings[spacing],
          inputStyles.base,
          inputStyles.sizes[size],
        )}
        ref={ref}
        {...props}
      />
    );
  });
  Input.displayName = "Input";
  

export default Input;