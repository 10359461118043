import axios from 'axios';
import useAuthStore from '../store/authStore';

export const BASEURL ='https://backend-staging.clubhook.com/api'


const axiosInstance = axios.create({
  baseURL: `${BASEURL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to add the token to requests
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 ) {
      useAuthStore.getState().logout();
      // Redirect to login page or dispatch a logout action
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
