import React from 'react';
import { Container } from '../../../components/ui';
import Table from '../../../components/ui/table/Table';
import TableHeader from '../../../components/ui/table/TableHeader';
import TableBody from '../../../components/ui/table/TableBody';
import TableRow from '../../../components/ui/table/TableRow';
import TableCell from '../../../components/ui/table/TableCell';
import TenantsData from '../../../utils/data/tenants';
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import { EditIcon } from '../../../assets/icons';

const TenantsTable = () => {
  const columns = ["Name" , " Business Type" ]
   
   

  const actions = [
    {
      icon: <EditIcon />,
      onClick: (row) => console.log('Edit', row),
      tooltip: 'Edit',
    },
    {
      icon: <ChevronRightIcon width="12" height="16" />,
      onClick: (row) => console.log('View details', row),
      tooltip: 'View details',
    },
  ];

  return (
    <Container >
      <Table>
        <TableHeader columns={columns} hasActions />
        <TableBody>
          {TenantsData.map((row, index) => (
            <TableRow key={index}>
                 <TableCell className='lg:w-[25%]  '>
                 <div className="flex flex-col">
                  <span className="text-base2 font-medium">{row.name}</span>
                  <span className="text-small font-medium text-secondary-grey">{row.email}</span>
                </div>
              </TableCell>
                            
                <TableCell>{row.type}</TableCell>
             
              <TableCell className="flex justify-end">
                <div className="flex gap-2 items-center">
                  {actions.map((action, actionIndex) => (
                    <button
                      key={actionIndex}
                      className="p-2"
                      title={action.tooltip}
                      onClick={() => action.onClick(row.id)}
                    >
                      {action.icon}
                    </button>
                  ))}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default TenantsTable;
