import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label, Select, Checkbox, Iterator } from "../../ui";

const Step2ClubConfiguration = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleIncrementBottles = () =>
    setFieldValue("totalBottles", values.totalBottles + 1);
  const handleDecrementBottles = () => {
    if (values.totalBottles > 1) setFieldValue("totalBottles", values.totalBottles - 1);
  };


  return (
    <div className="grid gap-5">
      <h2 className="text-h6 font-bold">Club Configuration and Pricing</h2>
      
      <div className="flex flex-col gap-2">
        <Label htmlFor="billingPreference">How would you like to bill this club? *</Label>
        <Field
          name="billingPreference"
          as={Select}
          placeholder="Choose anyone"
        >
          <option value="" className="hidden">Choose anyone</option>
          <option value="monthly">Monthly</option>
          <option value="perShipment">Per Shipment</option>
        </Field>
        <ErrorMessage name="billingPreference" component="div" className="error-message text-red" />
      </div>

      {/* Checkbox Group */}
      <div className="space-y-4 mb-6">
        <div className="flex items-start">
          <Field name="allowPricingVisibility" type="checkbox" as={Checkbox} />
          <Label htmlFor="allowPricingVisibility" className="ml-2">
            Allow club members to see bottle pricing and total cost of selections
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="billTotalAmount" type="checkbox" as={Checkbox} />
          <Label htmlFor="billTotalAmount" className="ml-2">
            Bill club members the total amount from the wines selected during shipments
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="allowMoreBottles" type="checkbox" as={Checkbox} />
          <Label htmlFor="allowMoreBottles" className="ml-2">
            Allow club members to select more bottles than the minimum required
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="notifyAdmin" type="checkbox" as={Checkbox} />
          <Label htmlFor="notifyAdmin" className="ml-2">
            Send an email notification to admin when a member selects their bottles
          </Label>
        </div>
      </div>

      <div className="flex justify-between items-start gap-4">
      <div className="flex flex-col items-start justify-between  gap-2">
              <Label htmlFor="totalBottles" className="mr-4">Number of bottles</Label>
              <div className="flex items-center  h-full">
              <Iterator
                    handleDecrement={ handleDecrementBottles }
                    handleIncrement={ handleIncrementBottles }
                    name='totalBottles'
                    id='totalBottles'
                    value={values.totalBottles}
                    className=' h-full '
                  />
              </div>
            </div>
            <ErrorMessage name="totalBottles" component="div" className="error-message text-red" />

            <div className="flex-1 flex flex-col  h-full">
              <Label htmlFor="pricePerShipment">Price per Shipment/Month *</Label>
              <Field
                name="pricePerShipment"
                as={Input}
                type="number"
                placeholder="Enter price per shipment/month"
                className="block w-full mt-2  border border-[#DCDCDC] 
                 rounded-md text-base2 font-normal"
              />
              <ErrorMessage name="pricePerShipment" component="div" className="error-message text-red" />
            </div>

            <div className="flex-1 flex flex-col  justify-start h-full ">
              <Label htmlFor="shipmentOption">Shipment Option *</Label>
              <Field
                name="shipmentOption"
                as={Select}
                className="block h-full max-h-[56px] mt-2 border border-[#DCDCDC]  rounded-md"
                placeholder="Choose anyone"
              >
                <option value="">Choose anyone</option>
                <option value="standard">Standard</option>
                <option value="express">Express</option>
              </Field>
              <ErrorMessage name="shipmentOption" component="div" className="error-message text-red" />
            </div>
      </div>
     

      <div className="space-y-4 ">
        <div className="flex items-start">
          <Field name="applyTaxAtCheckout" type="checkbox" as={Checkbox} />
          <Label htmlFor="applyTaxAtCheckout" className="ml-2 ">Should tax be applied at checkout?</Label>
        </div>

        <div>
          <Label htmlFor="taxRate">Tax Rate % *</Label>
          <Field
            name="taxRate"
            as={Input}
            type="number"
            placeholder="Enter tax rate"
            className="block w-full mt-2 p-2 border border-[#DCDCDC] rounded-md"
          />
          <ErrorMessage name="taxRate" component="div" className="error-message text-red" />
        </div>

        <div>
          <Label htmlFor="taxApplication">How will tax apply? *</Label>
          <Field
            name="taxApplication"
            as={Select}
            className="block h-full min-h-[56px] w-full mt-2 p-2 border border-[#DCDCDC] rounded-md"
            placeholder="Choose anyone"
          >
            <option value="">Choose anyone</option>
            <option value="stateOnly">State Only</option>
            <option value="federal">Federal</option>
          </Field>
          <ErrorMessage name="taxApplication" component="div" className="error-message text-red" />
        </div>

        <div className="flex items-start">
          <Field name="applyTaxToShipping" type="checkbox" as={Checkbox} />
          <Label htmlFor="applyTaxToShipping" className="ml-2">Apply tax to shipping costs too?</Label>
        </div>

        <div className="flex items-start">
          <Field name="applyTaxToState" type="checkbox" as={Checkbox} />
          <Label htmlFor="applyTaxToState" className="ml-2">Only apply tax to customers residing in your state?</Label>
        </div>

        <div className="flex items-start">
          <Field name="delayBillingUntilShipment" type="checkbox" as={Checkbox} />
          <Label htmlFor="delayBillingUntilShipment" className="ml-2">
            Don't bill club members until first shipment
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="applyDiscountToBottleCosts" type="checkbox" as={Checkbox} />
          <Label htmlFor="applyDiscountToBottleCosts" className="ml-2">
            Apply a discount to bottle costs for each member?
          </Label>
        </div>
      </div>
    </div>
  );
};

export const step2Validation = Yup.object().shape({
  billingPreference: Yup.string().required("Please choose a billing option"),
  totalBottles: Yup.number().required("Number of bottles is required").min(1, "At least 1 bottle is required"),
  pricePerShipment: Yup.number().required("Price per shipment/month is required"),
  shipmentOption: Yup.string().required("Please select a shipment option"),
  taxRate: Yup.number().required("Tax rate is required").min(0, "Tax rate cannot be negative"),
  taxApplication: Yup.string().required("Please choose how tax will apply"),
});

export default Step2ClubConfiguration;
