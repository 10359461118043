import React from "react";

const HomeIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5 17.1403C17.5 17.6005 17.1269 17.9736 16.6667 17.9736H3.33333C2.8731 17.9736 2.5 17.6005 2.5 17.1403V8.38119C2.5 8.12403 2.61873 7.88128 2.82172 7.7234L9.48842 2.53821C9.78933 2.30416 10.2107 2.30416 10.5116 2.53821L17.1782 7.7234C17.3812 7.88128 17.5 8.12403 17.5 8.38119V17.1403ZM15.8333 16.307V8.78876L10 4.25173L4.16667 8.78876V16.307H15.8333Z'
      fill='currentColor'
    />
  </svg>
);

export default HomeIcon;
