
import * as React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox, Input, Label, TextArea } from '../../../../components/ui'


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  permissions: Yup.object().shape({
    dashboard: Yup.array(),
    customers: Yup.array(),
    companies: Yup.array(),
    contacts: Yup.array(),
    logins: Yup.array(),
    histories: Yup.array(),
    loginTypes: Yup.array(),
    categories: Yup.array()
  })
})

const initialValues = {
  name: '',
  description: '',
  permissions: {
    dashboard: [],
    customers: [],
    companies: [],
    contacts: [],
    logins: [],
    histories: [],
    loginTypes: [],
    categories: []
  }
}

const permissions = {
    dashboard: [
        'dashboard',
        'general-statistics',
        'liquidity-statistics',
        'reach-and-return-per-month',
        'reach-per-week'
    ],
    customers: [
        'customers',
        'customers-view',
        'customers-create',
        'customers-update',
        'customers-delete'
    ],
    companies: [
        'companies',
        'companies-view',
        'companies-create',
        'companies-update',
        'companies-delete',
        'companies-contacts',
        'companies-histories',
        'companies-logins'
    ],
    contacts: [
        'contacts',
        'contacts-view',
        'contacts-create',
        'contacts-update',
        'contacts-delete'
    ],
    logins: [
        'access-data',
        'access-data-view',
        'access-data-create',
        'access-data-update',
        'access-data-delete'
        
    ],
    histories: [
        'histories',
        'histories-view',
        'histories-create',
        'histories-update',
        'histories-delete'
    ],
    loginTypes: [
        'login-types',
        'login-types-view',
        'login-types-create',
        'login-types-update',
        'login-types-delete'
    ],
    categories: [
        'categories',
        'categories-view',
        'categories-create',
        'categories-update',
        'categories-delete'
    ]
}

const AddRoleForm = () => {
  const handleSubmit = (values) => {
    console.log(values)
    // Handle form submission
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form className="space-y-8">
          <div className="space-y-4">
            <div>
              <Label htmlFor="name">
                Name <span className="text-red">*</span>
              </Label>
              <Field
                as={Input}
                type="text"
                id="name"
                name="name"
                className={errors.name && touched.name ? 'outline-red' : ''}
              />
              {errors.name && touched.name && (
                <p className="text-sm text-red mt-1">{errors.name}</p>
              )}
            </div>

            <div>
              <Label htmlFor="description" className>
                Description <span className="text-red">*</span>
              </Label>
              <Field
                as={TextArea}
                id="description"
                name="description"
                className={errors.description && touched.description ? 'outline-red' : ''}
              />
              {errors.description && touched.description && (
                <p className="text-sm text-red mt-1">{errors.description}</p>
              )}
            </div>
          </div>

          <div className="">
            <h2 className="text-lg font-semibold pt-2 border-t border-border">Permissions</h2>
            
          <div className='flex gap-5 justify-start flex-wrap '>
          {Object.entries(permissions).map(([section, perms]) => (
              <div key={section} className='flex-1'>
                <div className="pt-6">
                  <h3 className="text-base font-medium capitalize mb-4">{section}</h3>
                  <div className="flex flex-col min-w-[220px]   items-start gap-4">
                    {perms.map((permission) => (
                      <div key={permission} className="flex  w-full items-center space-x-2">
                        <Field
                          type="checkbox"
                          name={`permissions.${section}`}
                          value={permission}
                          as={Checkbox}
                          id={permission}
                        />
                        <Label
                          htmlFor={permission}
                          className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {permission}
                        </Label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          </div>

          <div className="flex justify-end space-x-4 py-5">
            <Button type="button" variant="outline">
              Cancel
            </Button>
            <Button type="submit">
              Create Role
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddRoleForm