import { create } from 'zustand'

const modalStore = create((set) => ({
  modals: {},
  openModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: true } })),
  closeModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: false } })),
  toggleModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: !state.modals[modalId] } })),
}))

export default modalStore

