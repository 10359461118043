import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "../../../components/ui";
import { useResetPasswordMutation } from "../../../hooks/useLoginMutation";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate , isError , isPending } =useResetPasswordMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
      return;
    } else {
      setErrorMessage("");

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      mutate( { newPassword, token });

    }
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword !== e.target.value) {
      setErrorMessage("Passwords do not match. Please try again.");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <div className='p-[40px] w-full items-center flex justify-center select-none '>
      <form onSubmit={handleSubmit} className='p-[40px] w-[100%]'>
        <div>
          <h2 className='text-[45px] font-bold'>Reset Password</h2>
          <p className='text-small mb-6 text-new-secondary-gray'>
            Please enter a new password below.
          </p>
        </div>

        <div className='mb-4 relative'>
          <label className='block text-base2 font-medium text-new-secondary-gray mb-2'>
            New Password
          </label>
          <div className='relative'>
            <Input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder='Enter your new password'
              required
            />
            <span
              className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
              onClick={() => setShowNewPassword((prev) => !prev)}
            >
              {showNewPassword ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.218c1.708-2.516 4.445-4.218 8.02-4.218 3.574 0 6.312 1.703 8.02 4.218a11.994 11.994 0 010 7.564c-1.708 2.516-4.445 4.218-8.02 4.218-3.574 0-6.312-1.703-8.02-4.218a11.994 11.994 0 010-7.564z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 3l18 18M10.477 10.477a2.5 2.5 0 003.046 3.046M9.879 9.879A3 3 0 0112 6c3.222 0 6 1.5 8 4a11.994 11.994 0 01-1.871 1.865M16.121 16.121A3 3 0 0112 18c-3.222 0-6-1.5-8-4a11.994 11.994 0 01.748-.953'
                  />
                </svg>
              )}
            </span>
          </div>
        </div>

        <div className='mb-4 relative'>
          <label className='block text-base2 font-medium text-new-secondary-gray mb-2'>
            Confirm Password
          </label>
          <div className='relative'>
            <Input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => handleChangeConfirmPassword(e)}
              placeholder='Confirm your new password'
              required
              className={` ${
                errorMessage ? "error-input " : ""
              }`}
            />
            <span
              className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.218c1.708-2.516 4.445-4.218 8.02-4.218 3.574 0 6.312 1.703 8.02 4.218a11.994 11.994 0 010 7.564c-1.708 2.516-4.445 4.218-8.02 4.218-3.574 0-6.312-1.703-8.02-4.218a11.994 11.994 0 010-7.564z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 3l18 18M10.477 10.477a2.5 2.5 0 003.046 3.046M9.879 9.879A3 3 0 0112 6c3.222 0 6 1.5 8 4a11.994 11.994 0 01-1.871 1.865M16.121 16.121A3 3 0 0112 18c-3.222 0-6-1.5-8-4a11.994 11.994 0 01.748-.953'
                  />
                </svg>
              )}
            </span>
          </div>
          {errorMessage && <p className="text-[#F91724] text-small py-2">{errorMessage}</p>}

        </div>



        <div className='flex gap-[16px]'>
          <Link className='w-full' to={"/login"}>
            <Button
              type='submit'
              aschild
              className={`w-full `}
              variant='outline'
              disabled={isPending}
            >
              Back
            </Button>
          </Link>

          <Button type='submit' disabled={isPending} className='w-full'>
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
