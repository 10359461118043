import React from 'react';

const DeleteIcon = ({ width = 20, height = 18, fill = "#A0A5AB", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      className='text-[#A0A5AB] hover:text-red'
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 17V4L18 0H2L0 4.004V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17ZM2 6H18V16H2V6ZM3.236 2H16.764L17.764 4H2.237L3.236 2ZM13 8H7V10H13V8Z"
        fill='currentColor'
      />
    </svg>
  );
};

export default DeleteIcon;
