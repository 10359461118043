import React, {useState, useEffect, useRef} from "react";
import {Formik, Form} from "formik";
import {Button} from "../../ui";
import {ArrowBackIcon} from "../../../assets/icons";
import {Link, useNavigate} from "react-router-dom"; 
import emailSteps from "../../../utils/steps/emailSteps";
import ProgressTracker from "../ProgressTracker";
import Step1EmailCampaign, { step1EmailCampaignValidation } from "./Step1EmailCampaign";
import Step2CreateMessage, { Step2CreateMessageValidation } from "./Step2CreateMessage";
import Step3RefineAudience, { Step3RefineAudienceValidation } from "./Step3RefineAudience";
import Step4SelectRecepients, { Step4SelectRecipientsValidation } from "./Step4SelectRecepients";
import useNotification from "../../../hooks/useNotification";

const steps = [
  {component: Step1EmailCampaign, validationSchema: step1EmailCampaignValidation},
  {component: Step2CreateMessage, validationSchema: Step2CreateMessageValidation},
  {component: Step3RefineAudience, validationSchema: Step3RefineAudienceValidation},
  {component: Step4SelectRecepients, validationSchema: Step4SelectRecipientsValidation},
];

const initialValues = {
  audience: "entire-club",
  selectedClub: "",
  isReleaseDateRelated: false, // Initialize to a boolean value
  shipmentDate: "",
  subject: "",
  body: "",
  audienceTodiscord: [],
  selectedUsers : [],
};

function SendEmailForm() {
  const navigate = useNavigate();
  const {showToast , STATUSES } = useNotification();


  const [step, setStep] = useState(0);

  const formikRef = useRef(null);

  const CurrentStepComponent = steps[step].component;


  const handleNext = (values, actions) => {
    if (step < steps.length - 1) {
      setStep(step + 1);

    } else {
      console.log("Form Submitted", values);
      

      // Simulate API request, then navigate to home

      setTimeout(() => {
        navigate("/"); // Redirect to home page
        showToast("Email sent successfully", STATUSES.SUCCESS);

      }, 1000);

      // Uncomment when ready for API integration
      // api.submitForm(values).then(() => {
      //   navigate("/");
      // });

      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  // Trigger validation on step change
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm(); // Re-validate the form based on the new step's schema
      formikRef.current.setTouched({}); // Reset touched state to prevent errors from showing immediately
    }
    // Get all the values from the form
    const values = formikRef.current.values;
    console.log("Send Email Step Data:", values);
  }, [step]);

  return (
    <div className="max-w-screen-xxl w-full mx-auto flex flex-col items-center justify-center gap-6">
      <div className="max-w-[556px] w-full">
        <Link to="/">
          <Button aschild variant="ghost" size="sm">
          <ArrowBackIcon className="text-black" />
          </Button>
        </Link>
        <h1 className="font-bold text-xl pt-2">Email Campaigns</h1>
      </div>

      <div className="max-w-[556px] w-full">
      <ProgressTracker currentStep={step} steps={emailSteps} />

      </div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={steps[step].validationSchema}
        onSubmit={handleNext}
        validateOnMount
        validateOnChange
      >
        {({isValid, values}) => (
          <Form className="flex flex-col gap-8 w-full items-center  email_form ">
            <CurrentStepComponent values={values} />
            <div className="flex justify-end gap-3 text-base2 font-medium w-full 
            max-w-[556px] action_form_buttons ">
              {step > 0 ? (
                <Button
                  variant="outline"
                  type="button"
                  onClick={handleBack}
                  className="outline-1"
                >
                  Previous
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => {}}
                  className="btn"
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="default"
                type="submit"
                disabled={!isValid}
                className="btn btn-primary text-base2 font-medium "
              >
                {step === steps.length - 1 ? "Sent Message" : "Next"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SendEmailForm;
