import React from "react";

const TierMedalIcon = (props) => (
  <svg
    width={props.width || "24"}
    height={props.height || "25"}
    viewBox="0 0 24 25"
    fill='currentColor'
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 7.50002C14.1217 7.50002 16.1566 8.34288 17.6569 9.84317C19.1571 11.3435 20 13.3783 20 15.5C20 17.6218 19.1571 19.6566 17.6569 21.1569C16.1566 22.6572 14.1217 23.5 12 23.5C9.87827 23.5 7.84344 22.6572 6.34315 21.1569C4.84285 19.6566 4 17.6218 4 15.5C4 13.3783 4.84285 11.3435 6.34315 9.84317C7.84344 8.34288 9.87827 7.50002 12 7.50002ZM12 9.50002C10.4087 9.50002 8.88258 10.1322 7.75736 11.2574C6.63214 12.3826 6 13.9087 6 15.5C6 17.0913 6.63214 18.6174 7.75736 19.7427C8.88258 20.8679 10.4087 21.5 12 21.5C13.5913 21.5 15.1174 20.8679 16.2426 19.7427C17.3679 18.6174 18 17.0913 18 15.5C18 13.9087 17.3679 12.3826 16.2426 11.2574C15.1174 10.1322 13.5913 9.50002 12 9.50002ZM12 11L13.322 13.68L16.28 14.11L14.14 16.195L14.645 19.141L12 17.75L9.355 19.14L9.86 16.195L7.72 14.109L10.678 13.679L12 11ZM18 2.50002V5.50002L16.637 6.63802C15.5063 6.04379 14.2712 5.67399 13 5.54902V2.50002H18ZM11 2.49902V5.54902C9.72918 5.6738 8.49449 6.04326 7.364 6.63702L6 5.50002V2.50002L11 2.49902Z"
      fill='currentColor'
    />
  </svg>
);

export default TierMedalIcon;