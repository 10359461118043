import React from "react";
import AddClubForm from "../../../components/forms/addClubForm";

export default function AddClubPage() {
  return (
    <div className="p-4">
      <AddClubForm />
    </div>
  );
}
