import React from "react";

const WineIcon = (props) => (
  <svg
    width={props.width || "25"}
    height={props.height || "25"}
    viewBox="0 0 25 25"
    fill='currentColor'
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.63384 2.5L5.51184 10.359C5.20884 12.473 6.03284 14.289 7.39184 15.547C8.49684 16.569 9.95984 17.232 11.5018 17.434V20.5H6.50084V22.5H18.5008V20.5H13.5008V17.434C15.0408 17.232 16.5048 16.569 17.6098 15.546C18.9688 14.289 19.7918 12.473 19.4898 10.359L18.3688 2.5H6.63384ZM10.5008 7.5C9.67084 7.5 8.66284 7.742 7.87184 7.976L8.36884 4.5H16.6338L17.1238 7.931C16.2858 8.207 15.3908 8.5 14.5008 8.5C13.1088 8.5 11.9288 7.5 10.5008 7.5Z"
      fill='currentColor'
    />
  </svg>
);

export default WineIcon;