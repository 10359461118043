function Footer() {
  return (
    <footer className='flex justify-center min-h-[50px] border-t items-center text-secondary-grey'>
      <p>
        © 2010-2024 <a className="text-blue" href='https://www.thewineclub.com'>The Wine Club</a>. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
