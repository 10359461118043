import React from "react";

const ReleasesIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.47063 15.4736C7.26845 16.887 6.05286 17.9736 4.5835 17.9736C3.11413 17.9736 1.89855 16.887 1.69636 15.4736H0.833496V5.47359C0.833496 5.01336 1.2066 4.64026 1.66683 4.64026H13.3335C13.7937 4.64026 14.1668 5.01336 14.1668 5.47359V7.14026H16.6668L19.1668 10.52V15.4736H17.4707C17.2684 16.887 16.0528 17.9736 14.5835 17.9736C13.1142 17.9736 11.8986 16.887 11.6963 15.4736H7.47063ZM12.5002 6.30693H2.50016V13.0157C3.02955 12.4754 3.76738 12.1403 4.5835 12.1403C5.747 12.1403 6.75137 12.8215 7.21948 13.8069H11.9475C12.0872 13.5128 12.2748 13.2456 12.5002 13.0157V6.30693ZM14.1668 11.3069H17.5002V11.0694L15.8266 8.80693H14.1668V11.3069ZM14.5835 16.3069C15.1277 16.3069 15.5907 15.9591 15.7623 15.4736C15.8084 15.3433 15.8335 15.203 15.8335 15.0569C15.8335 14.3666 15.2738 13.8069 14.5835 13.8069C13.8932 13.8069 13.3335 14.3666 13.3335 15.0569C13.3335 15.203 13.3586 15.3433 13.4047 15.4736C13.5762 15.9591 14.0392 16.3069 14.5835 16.3069ZM5.8335 15.0569C5.8335 14.3666 5.27385 13.8069 4.5835 13.8069C3.89314 13.8069 3.3335 14.3666 3.3335 15.0569C3.3335 15.203 3.35856 15.3433 3.40462 15.4736C3.57622 15.9591 4.03924 16.3069 4.5835 16.3069C5.12775 16.3069 5.59077 15.9591 5.76237 15.4736C5.80843 15.3433 5.8335 15.203 5.8335 15.0569Z'
      fill='currentColor'
    />
  </svg>
);

export default ReleasesIcon;
