import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label, Select, TextArea } from "../../ui";
import { Iterator } from "../../ui/Iterator";

const Step1ClubInfo = () => {
  const { values, setFieldValue } = useFormikContext();

  // Increment and decrement functionality for "Max No. of Members"
  const handleIncrement = () =>
    setFieldValue("maxMembers", values.maxMembers + 1);
  const handleDecrement = () => {
    if (values.maxMembers > 1)
      setFieldValue("maxMembers", values.maxMembers - 1);
  };

  return (
    <div className='grid gap-[16px]'>
      <h2 className='text-xl font-bold mb-4'>Club Information</h2>

      <div className='flex justify-between items-start gap-4  '>
        <div className='flex-1 flex flex-col items-start justify-between  h-full gap-2'>
          <Label htmlFor='clubName'>Club Name *</Label>
          <Field name='clubName' as={Input} placeholder='Enter Club Name' className="h-full px-2" />
          <ErrorMessage
            name='clubName'
            component='div'
            className='error-message text-red'
          />
        </div>
        <div>
          <div className='flex flex-col items-center justify-between  gap-2 '>
            <Label htmlFor='maxMembers'>Max No. of Members</Label>
            <Iterator
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              name='maxMembers'
              id='maxMembers'
              value={values.maxMembers}
              className='max-h-[56px] h-full'

            />
           
          </div>
          
        </div>
      </div>

      <div className="flex flex-col items-start gap-2">
        <Label htmlFor='description'>Club Description *</Label>
        <Field
          name='description'
          as={TextArea}
          placeholder='Enter Club Description'
          
        />
        <ErrorMessage
          name='description'
          component='div'
          className='error-message text-red'

        />

       
      </div>

      <div className="">
        <Label htmlFor='clubType'>What type of wine club is this? *</Label>
        <Field name='clubType' as={Select} placeholder='Choose anyone'>
          <option value=''>Choose anyone</option>
          <option value='red'>Red Wine Club</option>
          <option value='white'>White Wine Club</option>
          <option value='mixed'>Mixed Wine Club</option>
        </Field>
        <ErrorMessage
          name='clubType'
          component='div'
          className='error-message text-red'
        />
      </div>
    </div>
  );
};

// Validation schema for Step 1
export const step1Validation = Yup.object().shape({
  clubName: Yup.string().required("Club name is required"),
  description: Yup.string().required("Description is required"),
  maxMembers: Yup.number()
    .required("Max number of members is required")
    .min(1, "Must be at least 1 member"),
  clubType: Yup.string().required("Please select a wine club type"),
});

export default Step1ClubInfo;
