import React, { useState, useMemo } from 'react';
import { Button, Container, Tabs, Tag } from '../../../components/ui';
import { EditIcon, SettingsIcon } from '../../../assets/icons';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import Table from '../../../components/ui/table/Table';
import TableHeader from '../../../components/ui/table/TableHeader';
import TableBody from '../../../components/ui/table/TableBody';
import TableRow from '../../../components/ui/table/TableRow';
import TableCell from '../../../components/ui/table/TableCell';
import { clubsData } from '../../../utils/data/clubs';

// Mock data (replace with your actual data fetching logic)


const ClubsTable = () => {
  const [isLive, setIsLive] = useState(true); // true for live, false for archive
  const tabs = [
    { label: 'Live Club', value: true },  // true indicates "live"
    { label: 'Archive Club', value: false }, // false indicates "archive"
  ];

  const filteredClubs = useMemo(() => {
    return clubsData.filter((club) => club.isArchived !== isLive);
  }, [isLive]);



  const columns = ["Club" , "Members" ,"Status" ,"Bottles" , "Price" ];

  const actions = [
    {
      icon: <EditIcon />,
      onClick: (row) => console.log('Edit', row),
      tooltip: 'Edit',
    },
    {
      icon: <SettingsIcon />,
      onClick: (row) => console.log('View details', row),
      tooltip: 'Edit Role',
    },
    {
      icon: <DeleteIcon />,
      onClick: (row) => console.log('View details', row),
      tooltip: 'Delete Role',
    },
  ];

  return (
    <Container type="flex" className="px-0 py-3" size="full">
      {/* Tabs */}
      <Tabs tabs={tabs} activeTab={isLive} onTabChange={setIsLive} />

      {/* Clubs List */}
      <Container className="mt-4 px-4" size='xxl'>
      <Table>
          <TableHeader columns={columns} hasActions />
          <TableBody>
            {filteredClubs.map((row, index) => (
              <TableRow key={index}>
                 <TableCell className=' lg:w-[25%] '>
                 <div className='inline-flex gap-2 items-center justify-start'>
                  <img className='w-full h-auto max-w-[48px] '  src={row.featuredImage} alt="Club" />
                  <h6 className='text-base2 font-medium text-[#333333]'>{row.name}</h6>
                 </div>
                </TableCell>
                              
                  <TableCell className='lg:w-[10%] text-base2 font-medium text-[#333333] '>{row.members}</TableCell>
                  <TableCell className='lg:w-[10%]'>
                    {row.status === 'Public' ? (
                      <Tag variant='blue' sizes='md' text={row.status}/>
                    ) : (
                      <Tag variant='warning' sizes='md' text={row.status}/>
                    )}
                  </TableCell>

                  <TableCell className='lg:w-[15%] text-base2 font-medium text-[#333333]'>{row.bottles} Bottle Per Release</TableCell>
                  <TableCell>
                    <div className='flex flex-col items-start justify-center'>
                      <span className='text-base2 font-medium text-[#333333]'>${row.price}</span>
                      <span className='text-caption font-medium text-[#666666]'>Per release</span>
                    </div>
                  </TableCell>


                  <TableCell className='text-[#A0A5AB]' >
                  <div className="flex justify-end items-center  w-full h-full">
                  {actions.map((action, actionIndex) => (
                        <Button
                        key={actionIndex}
                        variant="ghost"
                        className=" text-center flex justify-center "
                        onClick={() => action.onClick(row)}
                        size="icon"
                        title={action.tooltip}
                      >
                        {action.icon}
                      </Button>
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Container>
  );
};

export default ClubsTable;
