import { useQuery } from '@tanstack/react-query';
import useAuthStore from '../store/authStore';
import { useEffect } from 'react';
import { getUserData } from '../api/user/userQueries';
import { queryKeys } from '../api/queryKeys';



export const useAuth = () => {
  const { token, user, setUser, logout ,checkAuthStatus ,  } = useAuthStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: queryKeys.USER,
    queryFn: getUserData,
    enabled: !!token && !user,
    retry: false,
  });

  useEffect(() => {
    
 
    if (data) {


      const { data: {user} } = data;
      setUser(user);
    }
  }, [ data, isError, logout, setUser, token]);

  useEffect(() => {
    if (isError) {
      logout();
    }
    checkAuthStatus();

  }, [checkAuthStatus, isError, logout]);


  return {
    user: user || data?.data?.user,
    isLoading,
    error,
    isAuthenticated: !!(user || data),
  };
};

