import React from "react";

export const ChevronDownIcon = (props) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_35_5237)">
      <path
        d="M7.99993 3.7544L11.2998 0.45459L12.2426 1.3974L7.99993 5.64007L3.75732 1.3974L4.70013 0.45459L7.99993 3.7544Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_35_5237"
        x="-0.242676"
        y="0.45459"
        width="16.4854"
        height="13.1855"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_35_5237"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_35_5237"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ChevronUpIcon = (props) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 10"
    fill="none"
    className=""
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_35_5237)">
      <path
        d="M7.99993 2.33567L3.75732 6.57834L4.70013 7.52115L7.99993 4.22134L11.2998 7.52115L12.2426 6.57834L7.99993 2.33567Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_35_5237"
        x="-0.242676"
        y="0.335693"
        width="16.4854"
        height="13.1855"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_35_5237"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_35_5237"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);