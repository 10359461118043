import React from 'react'
import { Button, Container } from '../../../components/ui'
import { TiPlus } from "../../../assets/icons";
import { Link } from 'react-router-dom';
import ClubsTable from './ClubsTable';



const ClubPage = () => {


  return (
    <Container type="flex" className="px-0 py-3 " size="full">
      <div className='flex px-5 justify-between'>
        <h5 className="text-h5 font-bold ">Manage Club</h5>
        <Link to='/club-management/clubs/add-club'>
          <Button aschild variant='default' >
            <TiPlus />   Add New Club
          </Button>
        </Link>
      </div>

      <ClubsTable />


    </Container>

  )
}

export default ClubPage
