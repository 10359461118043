import React from 'react';

const FilterIcon = (props) => (
  <svg
    width={props.width || "24"}
    height={props.height || "24"}
    viewBox="0 0 24 24"
    fill={props.fill || "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 14V20L10 22V14L4 5V3H20V5L14 14ZM6.404 5L12 13.394L17.596 5H6.404Z"
      fill={props.fill || "currentColor"}
    />
  </svg>
);

export default FilterIcon;
