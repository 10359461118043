import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "../../../components/ui";
import { useForgotPasswordMutation } from "../../../hooks/useLoginMutation";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(""); // State to track email validation error

  const { mutate , isPending ,isError , isSuccess} = useForgotPasswordMutation()

  const validateEmail = (email) => {
    // Basic regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Remove error if the email becomes valid
    if (validateEmail(value)) {
      setError("");
    } else {
      setError("Email incorrect");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Email incorrect");
      return;
    }

    setError(""); // Clear any errors

    mutate(
      { email },
    );

  };


  return (
    <div className='p-[40px] w-full items-center  flex justify-center'>
      <form onSubmit={handleSubmit} className='p-[40px] w-[100%]'>
        <div>
          <h2 className='text-xxl font-bold'>Forgot Password</h2>
          <p className='text-small mb-6 text-new-secondary-gray'>
            No Worries we will send you reset instructions.
          </p>
        </div>

        <div className='mb-4'>
          <label className='block text-base2 font-medium text-new-secondary-gray mb-2'>
            Email
          </label>
          <Input
            type="email"
            value={email}
            onChange={handleInputChange}
            placeholder="Enter your email"
            required
            autoComplete="email"
            id="email"
            name="email"
            className={`border ${
              error ? "error-input " : ""
            }`}
          />
          {error && <p className="text-[#F91724] text-small mt-2">{error}</p>}
        </div>

        {isSuccess && <p className='text-green-600 text-sm mb-4'>
          Please check your email, we’ve sent you recovery link
          </p>}

        <div className='flex gap-[16px] pt-5'>
          <Link className='w-full' to={"/login"}>
            <Button
              type='submit'
              aschild
              className={`w-full `}
              variant='outline'
            >
              Back
            </Button>
          </Link>

          <Button type='submit' disabled={isPending} className={`w-full`}>
            {isPending ? "Sending..." : "Send Email"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
