
const dummyUsersAccounts = [
  {
    id: 1,
    name: 'John Doe',
    email: 'johnDoe@example.com',
    club:'SAVI Society Wine Club',
  },
  {
    id: 2,
    name: 'Darrell Stewarde',
    email: 'darrelteward@example.com',
    club:'SAVI Society Wine Club',
  },
  {
    id: 3,
    name: 'Ronald Richards',
    email: 'ronald@gmail.com',
    club:'LA FANTASIA Club',
  },
  {
    id: 4,
    name: 'Micheal Johnson',
    email: 'micJohn@example.com',
    club:'Elite Vintage Winery',
    }, 

]

export default dummyUsersAccounts;