import React, { useState } from "react";
import UpcomingBirthdays from "../../components/BirthdayCalendar";
import { metricsData , revenueData } from "../../utils/data/dummyData";
import MetricCard from "../../components/cards/MetricCard";
import { activeMemberData, averageSpendData} from "../../utils/data/dummyData";
import { LineChart, RevenueChart } from "../../components/charts";
import { Select } from "../../components/ui";

const DashboardPage = ({user}) => {
  const [timePeriod, setTimePeriod] = useState(""); // Track selected option



  const handleSelectChange = (event) => {
    setTimePeriod(event.target.value);
  }; 


  return (
    <div className='pl-4 py-6 pr-10 flex flex-col gap-x-[16px] gap-y-[24px]'>
      <div className="max-w-[165px] ">
        <Select
          name='timePeriod'
          id="timePeriod"
          placeholder='Time Period'
          onChange={handleSelectChange}
          value={timePeriod} // Bind value to state
          className=''
          size='sm'
        >
          <option value='1-year'>Last 12 months</option>
          <option value='three-months'>Last 3 months</option>
          <option value='six-months'>Last 6 Months</option>
          <option value='twelve-months'>Last 12 months</option>
        </Select>
      </div>
      
      <div className='grid grid-cols-3 gap-x-[16px] mb-[24px]'>
        <div className='col-span-3 lg:col-span-1 grid gap-[16px] grid-cols-4 lg:grid-cols-2'>
          {metricsData.map((metric, index) => (
            <MetricCard key={index} {...metric} />
          ))}
        </div>
        <div className='col-span-3 lg:col-span-2'>
          <RevenueChart data={revenueData} />
        </div>
      </div>

      <div className='grid grid-cols-3 gap-x-[16px] gap-y-[24px]'>
        <div className='col-span-1 md:col-span-2 grid grid-cols-1 gap-6'>
          <h6 className='text-h6 font-bold'>Active Member</h6>
          <LineChart data={activeMemberData} variant='default' />
          <LineChart data={averageSpendData} variant='primary' />
        </div>
        <UpcomingBirthdays />
      </div>
    </div>
  );
};

export default DashboardPage;
