import React, { useState, useEffect, useCallback } from "react";

const texts = [
  "Build a reliable platform that lets your customers sign up to be a member and take their pick with just a few clicks",
  "Learn something new today.",
  "Expand your knowledge.",
  "Achieve your goals with us.",
];

const TextCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="text-center py-10 space-y-3 w-full max-w-[472px] mx-auto">
      <h1 className="text-3xl font-semibold text-gray-800">
        Wine Club Management Online Platform
      </h1>

      <div className="relative h-12 overflow-hidden">
        {texts.map((text, index) => (
          <p
            key={index}
            className={`absolute text-base font-normal text-secondary-grey inset-0 flex items-center justify-center transition-all duration-500 ease-in-out ${
              index === activeIndex
                ? "opacity-100 transform translate-x-0"
                : index === (activeIndex - 1 + texts.length) % texts.length
                ? "opacity-0 transform -translate-x-full"
                : "opacity-0 transform translate-x-full"
            }`}
          >
            {text}
          </p>
        ))}
      </div>

      <div className="flex justify-center space-x-2 ">
        {texts.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`h-2 rounded-full transition-all duration-300 ${
              index === activeIndex
                ? "w-[35px] bg-primary"
                : "w-3 bg-primary20 hover:bg-blue-300"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default TextCarousel;

