import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAuthStore from '../store/authStore';
import {  forgotPassword, loginUser , logoutUser, resetPassword } from '../api/auth/authQueries';
import useNotification from './useNotification';


export const useLoginMutation = () => {
  const login = useAuthStore((state) => state.login);
  const {showToast, STATUSES} = useNotification();

  return useMutation({
    mutationFn: loginUser,
    onSuccess: (user) => {

      const { data } = user;
      
      console.log('data', data);
      login(data);
      showToast('Login successful', STATUSES.SUCCESS);

    },
    onError: (error) => {
      showToast(
        'Login Failed , Please check your credentials',
        STATUSES.ERROR
      )
    },
  });
};


export const useLogoutMutation = () => {
  const logout = useAuthStore((state) => state.logout);
  const {showToast, STATUSES} = useNotification();


  return useMutation({
    mutationFn: logoutUser  ,
    onSuccess: () => {
      logout();
      showToast('Logout successful', STATUSES.SUCCESS);
    },
    onError: (error) => {
      showToast(
        'Something went wrong, Please try again',
        STATUSES.ERROR
      )
    },
  });
}


export const useForgotPasswordMutation = () => {
  const {showToast, STATUSES} = useNotification();

  return useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      showToast('Password reset link sent to your email', STATUSES.SUCCESS);
    },
    onError: (error) => {
      showToast(
        'Password reset failed, Please try again',
        STATUSES.ERROR
      )
    },
  });
}

export const useResetPasswordMutation = () => {
  const {showToast, STATUSES} = useNotification();

  return useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      showToast('Password reset successful', STATUSES.SUCCESS);
    },
    onError: (error) => {
      showToast(
        'Password reset failed, Please try again',
        STATUSES.ERROR
      )
    },
  });
}

