import React from 'react'
import cn from '../../utils/cn'





// No wrap on the text  
const tagStyles = {
  base: "rounded-full font-bold inline-block whitespace-nowrap",
  variants: {
    default: "text-green-700  bg-green-100 border-2 border-[#E2F7E2]",
    primary: "text-[#F90000]  bg-[#ffe6e6] border-2 border-[#ffe6e6]",
    secondary: "text-secondary-grey bg-[#F9F9F9]  border border-gray-200 cursor-pointer font-medium text-base2",
    warning: "text-[#EB9A05] bg-[#FFF6CC] ",
    blue: "text-[#0163FF] bg-[#E9F1FF] ",
  },
  sizes: {
    sm: "text-small  px-3 py-[4px] ",
    md: "text-base2 px-4 py-1 font-medium",
    lg: "text-base px-6 py-2",
  },
 
}

const Tag = ({text ,variant = "default" , sizes="sm",className}) => {


  return (
    <span  
     className={cn(
      className,
      tagStyles.base,
      tagStyles.sizes[sizes],
      tagStyles.variants[variant]
       )
      }>

     {text}

      </span>
  )
}

export default Tag
