import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Label, Select, ToggleSwitch, Button } from "../../ui";
import WineIcon from "../../../assets/icons/WineIcon";
import TierMedalIcon from "../../../assets/icons/TierMedalIcon";

const Step1EmailCampaign = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="grid gap-[24px] max-w-[556px] mx-auto w-full ">
      {/* Select Audience */}
      <div className="flex flex-col gap-4">
        <Label>Select your audience *</Label>
        <div className="flex gap-4  h-[69px]">
        <div 
          className={
            ` border rounded flex-1
            transition-all duration-300
            ${values.audience === "entire-club" ? "bg-[#3D55D726]" : "bg-white"}

            `}
        > 
        <Button
            type="button"
            variant="ghost"
          className={
            `${
              values.audience === "entire-club"
              ? " text-[#3D55D7]"
              : " text-secondary-grey"}
              w-full h-full
              `
          }
            onClick={() => setFieldValue("audience", "entire-club")}
          >
                   <WineIcon className=" mr-1 w-6 h-6 " />
                   Entire Club
          </Button>
        </div>

        <div 
          className={` border rounded flex-1
                        transition-all duration-300

              ${values.audience !== "entire-club" ? "bg-[#3D55D726]" : "bg-white"}

            `}
        > 
          <Button
            type="button"
            variant="ghost"
            className={
              `${
                values.audience !== "entire-club"
                ? " text-[#3D55D7]"
                : " text-secondary-grey"}
                w-full h-full
                `
            }
            onClick={() => setFieldValue("audience", "selected-tier")}
          >
            <TierMedalIcon  className=" mr-1 w-6 h-6 " />
            Selected Tier
          </Button>
          </div>
        </div>
        <ErrorMessage
          name="audience"
          component="div"
          className="error-message text-red"
        />
      </div>

      {/* Select Club */}
      <div className="flex flex-col gap-2">
        <Label htmlFor="selectedClub">Select which Club you’d like to message *</Label>
        <Field name="selectedClub" as={Select} placeholder="Select any one">
          <option value="">Select any one</option>
          <option value="club1">Club 1</option>
          <option value="club2">Club 2</option>
          <option value="club3">Club 3</option>
        </Field>
        <ErrorMessage
          name="selectedClub"
          component="div"
          className="error-message text-red"
        />
      </div>

      {/* Related to Release Date */}
      <div className="flex justify-between items-center">
        <Label htmlFor="isReleaseDateRelated">
          <span className="flex flex-col gap-1">
          Is the message related to a Release Date? *
          <span className="text-small font-normal text-secondary-grey">
          select 'yes' if you plan to include link for member selections
          </span>
          </span>
        </Label>
        <ToggleSwitch
          name="isReleaseDateRelated"
          checked={values.isReleaseDateRelated}
          onChange={(checked) => setFieldValue("isReleaseDateRelated", checked)}
        />
      </div>

      {/* Shipment Date */}

      {values.isReleaseDateRelated && (
        <div className="flex flex-col gap-2">
        <Label htmlFor="shipmentDate">Which shipment date is this in regard to? *</Label>
        <Field name="shipmentDate" as={Select} placeholder="Select date">
          <option value="">Select date</option>
          <option value="2024-06-27">Fri, 27 June 2024</option>
          <option value="2024-07-15">Mon, 15 July 2024</option>
        </Field>
        <ErrorMessage
          name="shipmentDate"
          component="div"
          className="error-message text-red"
        />
        </div>
      )}
     

      {/* Actions */}
     
    </div>
  );
};

// Validation schema for Step 1
export const step1EmailCampaignValidation = Yup.object().shape({
  audience: Yup.string().required("Please select your audience"),
  selectedClub: Yup.string().required("Please select a club"),
  shipmentDate: Yup.string().when("isReleaseDateRelated", {
    is: true,
    then: () => Yup.string()
        .required("Please select a shipment date"),
  }),
});


export default Step1EmailCampaign;
