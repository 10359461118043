import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Tag } from "../ui";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const LineChart = ({ data }) => {
  const { datasets } = data;
  const { icon, title, variant, variantText, data: chartData } = datasets[0];
  const maxValue = Math.max(...chartData);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        border: {
          display: false,
          dash: [5, 5],
        },
        beginAtZero: true,
        ticks: {
          stepSize: 200, // Y-axis labels increment by 200
          callback: (value) => `${(value / 1000).toFixed(1)}`, // Format Y-axis labels as "$10K", "$7.5K", etc.
          color: "#000000", // Black text
          font: {
            size: 12,
            family: "Inter", // Ensures consistent font
          },
        },
        grid: {
          color: "rgba(229, 231, 235, 0.4)", // Light grid color for a subtle look
          borderDash: [5, 5], // Dashed lines
        },
      },
      x: {
        border: {
          display: false,
          dash: [5, 5],
        },
        ticks: {
          color: "#000000", // Black text
          font: {
            size: 12,
            family: "Inter", // Consistent font style
          },
        },
        grid: {
          color: "rgba(229, 231, 235, 0.4)", // Light grid color for a subtle look
          borderDash: [5, 5], // Dashed lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hides legend
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}`, // Tooltip shows only the number
        },
      },
    },
  };

  return (
    <div className='bg-white h-full  rounded-lg   flex flex-col justify-between pb-4 gap-4 '>
      <div className='border border-gray-200 py-4 max-h-[420px]'>
        <div className='flex items-center justify-between border-b border-gray-100 pb-4 px-4 mb-4'>
          <div className='flex items-center '>
            <img src={icon} alt='Active Members Icon' className='w-14 h-14' />
            <div className='ml-4'>
              <h4 className='text-dark font-medium text-base2 '>{title}</h4>
              <span className='text-black font-bold text-xl'>{maxValue}</span>
            </div>
          </div>
          <div>
            <Tag variant={variant} text={variantText} />
          </div>
        </div>
        <div className='pb-4  pl-8 '>
          <Line data={data} options={options} height={300} />
        </div>
      </div>
    </div>
  );
};

export default LineChart;
