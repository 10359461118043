
export const DummyReleases = [
  {
    id: 1,
    date: "Fri, 27 June 2024",
    members: 16,
    status: "Completed",
  },
  {
    id: 2,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Completed",
  },
  {
    id: 3,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Upcoming",
  },
  {
    id: 4,
    date: "Fri, 27 June 2024",
    members: 10,
    status: "Draft",
  },
  {
    id: 5,
    date: "Fri, 27 June 2024",
    members: 14,
    status: "Completed",
  },
  {
    id: 6,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Completed",
  },
  {
    id: 7,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Upcoming",
  },
  {
    id: 8,
    date: "Fri, 27 June 2024",
    members: 40,
    status: "Draft",
  }
]