import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  HomeIcon,
  MembershipIcon,
  ClubManagementIcon,
  InventoryIcon,
  ReleasesIcon,
  EmailCampaignsIcon,
  EmailHistoryIcon,
  ReportsIcon,
  IntegrationsIcon,
  SettingsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
 
} from "../../assets/icons";
import logoMaster from "../../assets/images/wineclub_logo.png";
import useAuthStore from "../../store/authStore";

function Sidebar() {
  const { user } = useAuthStore();

  const location = useLocation();


  const [isManagementExpanded, setIsManagementExpanded] = useState(false);

  const toggleManagement = () => {
    setIsManagementExpanded(!isManagementExpanded);
  };

  return (
    <aside className="w-64 h-full bg-blue-bg flex flex-col p-4">
      <div className="text-2xl font-bold mb-8 text-secondary-grey">
        <img src={logoMaster} alt="Wine Club Logo" />
      </div>

      <nav className="flex flex-col space-y-2">
        <NavLink
          to="/"
          end
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <HomeIcon className="h-5 w-5 mr-3" />
          Home
        </NavLink>

        <NavLink
          to="/club-management/clubs"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <ClubManagementIcon className="h-5 w-5 mr-3" />
          Club Management
        </NavLink>

        <NavLink
          to="/memberships"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <MembershipIcon className="h-5 w-5 mr-3" />
          Membership
        </NavLink>

        <NavLink
          to="/inventory"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <InventoryIcon className="h-5 w-5 mr-3" />
          Inventory
        </NavLink>

        <NavLink
          to="/releases"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <ReleasesIcon className="h-5 w-5 mr-3" />
          Releases
        </NavLink>

        <NavLink
          to="/send-email"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <EmailCampaignsIcon className="h-5 w-5 mr-3" />
          Email Campaigns
        </NavLink>

        <NavLink
          to="/email-history"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <EmailHistoryIcon className="h-5 w-5 mr-3" />
          Email History
        </NavLink>

        <NavLink
          to="/reports"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <ReportsIcon className="h-5 w-5 mr-3" />
          Reports
        </NavLink>

        <NavLink
          to="/integrations"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <IntegrationsIcon className="h-5 w-5 mr-3" />
          Integrations
        </NavLink>

        <NavLink
          to="/settings"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey hover:bg-primary hover:text-white"
            }`
          }
        >
          <SettingsIcon className="h-5 w-5 mr-3" />
          Settings
        </NavLink>

        {(user?.isAdmin || user?.id === 1) && (
          <div>
            <button
              onClick={toggleManagement}
              className={`p-3 flex items-center rounded-lg w-full ${
                location.pathname.includes('/admin')
                  ? "bg-primary text-white"
                  : "text-secondary-grey hover:bg-primary hover:text-white"
              }`}
            >
              Admin Management
              {isManagementExpanded ? (
              <ChevronUpIcon className="h-4 w-4 ml-auto" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 ml-auto" />
              )}
            </button>
            {isManagementExpanded && (
              <div className="ml-4 mt-2 space-y-2">
                <NavLink
                  to="/admin/roles"
                  className={({ isActive }) =>
                    `p-2 flex items-center rounded-lg ${
                      isActive
                        ? "bg-primary text-white"
                        : "text-secondary-grey hover:bg-primary hover:text-white"
                    }`
                  }
                >
                  Roles & Permissions
                </NavLink>
                <NavLink
                  to="/admin/tenants"
                  className={({ isActive }) =>
                    `p-2 flex items-center rounded-lg ${
                      isActive
                        ? "bg-primary text-white"
                        : "text-secondary-grey hover:bg-primary hover:text-white"
                    }`
                  }
                >
                  Tenants
                </NavLink>
              </div>
            )}
          </div>
        )}
      </nav>
    </aside>
  );
}

export default Sidebar;