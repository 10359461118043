import MMRIcon from "../../assets/icons/MMRIcon.png";
import AverageCustomerValueIcon from "../../assets/icons/AverageCustomerValueIcon.png";
import ActiveMembersIcon from "../../assets/icons/ActiveMembersIcon.png";
import NewMembersIcon from "../../assets/icons/NewMembersIcon.png";
import AverageSpentIcon from "../../assets/icons/AverageSpentIcon.png";

export const metricsData = [
  {
    icon: MMRIcon,
    title: "Total MRR",
    value: "1000",
    percentage: "+3.82",
  },
  {
    icon: AverageCustomerValueIcon,
    title: "Average Customer Value",
    value: "1000",
    percentage: "+3.82",
  },
  {
    icon: ActiveMembersIcon,
    title: "Active Members",
    value: "800",
    percentage: "+3.82",
  },
  {
    icon: NewMembersIcon,
    title: "New Members",
    numbers: "11",
    period: "past 30 days",
    percentage: "+3.82",
  },
];

export const revenueData = {
  percentage: "+1.3",
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Revenue",
      data: [
        5000, 6000, 6500, 5000, 7000, 9000, 8000, 6000, 4000, 7000, 5000, 6000,
      ],
      backgroundColor: "#5A64B6",
      borderRadius: 5,
      barThickness: 26,
      borderSkipped: false,
    },
  ],
  options: {
    scales: {
      x: {
        grid: {
          display: false, // Remove grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Remove grid lines on the y-axis
        },
      },
    },
  },
};

export const activeMemberData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      icon: ActiveMembersIcon,
      title: "Active Members",
      data: [100, 500, 400, 600, 700, 800],
      backgroundColor: "#989ED1", // Filled area color
      borderColor: "#313DA4", // Line color
      borderWidth: 2,
      tension: 0.4, // Smooth curve
      fill: true, // Fill under the line
      pointRadius: 0, // Hides individual points,
      variant: "default",
      variantText: "+11 in last 30 days",
    },
  ],
};

export const averageSpendData = {
  type: "line",
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      icon: AverageSpentIcon,
      title: "Member Average Spend",
      data: [10, 20, 30, 40, 50, 42],
      fill: true,
      backgroundColor: "#989ED1", // Filled area color
      borderColor: "#313DA4", // Line color
      tension: 0.4,
      variant: "primary",
      variantText: "-4.5%",
    },
  ],
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const birthdayData = [
  {
    id: 1,
    name: "Courtney Henry",
    date: formatDate(new Date()), // Today
    profileImage: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    id: 2,
    name: "Jane Cooper",
    date: formatDate(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)), // This week
    profileImage: "https://randomuser.me/api/portraits/women/2.jpg",
  },
  {
    id: 3,
    name: "Brooklyn Simmons",
    date: formatDate(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)), // This week
    profileImage: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    id: 4,
    name: "Floyd Miles",
    date: formatDate(new Date(Date.now() + 15 * 24 * 60 * 60 * 1000)), // This month
    profileImage: "https://randomuser.me/api/portraits/men/4.jpg",
  },
  {
    id: 5,
    name: "Dianne Russell",
    date: formatDate(new Date(Date.now() + 20 * 24 * 60 * 60 * 1000)), // This month
    profileImage: "https://randomuser.me/api/portraits/women/5.jpg",
  },
];
