

import React from 'react'

const BirthdayCard = ({avatar , name , date}) => {
  return (
    <div
    className='flex justify-between items-center px-4 py-4' >
    <div className='flex items-center'>
      <img
        src={avatar}
        alt={name}
        className='w-10 h-10 rounded-full mr-3'
      />
      <p className='text-base text-[#333333] font-medium  '>{name}</p>
    </div>
    <span className='text-base2 font-semibold text-[#333333]'>{date}</span>
  </div>
  )
}

export default BirthdayCard
