import React from "react";
import {Container} from "../../../../components/ui";
import AddRoleForm from "./AddRoleForm";

const AddRolePage = () => {
  return (
    <Container type="flex" className="px-2 py-4 " size="lg">
      <h5 className="text-h5 font-bold flex-1">Add Role</h5>

      <AddRoleForm />
    </Container>
  );
};

export default AddRolePage;
