import React from 'react'
import { Container } from '../../../components/ui'
import { EditIcon } from '../../../assets/icons';
import Table from '../../../components/ui/table/Table';
import TableHeader from '../../../components/ui/table/TableHeader';
import TableBody from '../../../components/ui/table/TableBody';
import RolesData from '../../../utils/data/roles';
import TableRow from '../../../components/ui/table/TableRow';
import TableCell from '../../../components/ui/table/TableCell';
import DeleteIcon from '../../../assets/icons/DeleteIcon';

const RolesTable = () => {
    const columns = ["Name" , "Description" ]

    const actions = [
      {
        icon: <EditIcon />,
        onClick: (row) => console.log('Edit', row),
        tooltip: 'Edit',
      },
      {
        icon: <DeleteIcon />,
        onClick: (row) => console.log('View details', row),
        tooltip: 'Delete Role',
      },
    ];
  return (
      <Container >
        <Table>
          <TableHeader columns={columns} hasActions />
          <TableBody>
            {RolesData.map((row, index) => (
              <TableRow key={index}>
                 <TableCell className=' lg:w-[25%] '>
                 {row.name}
                </TableCell>
                              
                  <TableCell>{row.description}</TableCell>
               
                <TableCell className="flex justify-end">
                  <div className="flex gap-2 items-center">
                    {actions.map((action, actionIndex) => (
                      <button
                        key={actionIndex}
                        className="p-2"
                        title={action.tooltip}
                        onClick={() => action.onClick(row.id)}
                      >
                        {action.icon}
                      </button>
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  };

export default RolesTable
