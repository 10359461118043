import React from "react";
import cn from "../../utils/cn";
import Button from "./Button";
import  Input  from "./Input";

// NumberInput Component (for the bottle count with +/- buttons)
export const NumberInput = React.forwardRef(({ className, value, onChange, min = 0, max, ...props }, ref) => {
    const handleIncrement = () => {
      if (max === undefined || value < max) {
        onChange(value + 1);
      }
    };
  
    const handleDecrement = () => {
      if (value > min) {
        onChange(value - 1);
      }
    };
  
    return (
      <div className="flex items-center">
        <Button onClick={handleDecrement} variant="outline" size="icon" type="button">
          -
        </Button>
        <Input
          type="number"
          ref={ref}
          className={cn("w-16 text-center mx-2", className)}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          min={min}
          max={max}
          {...props}
        />
        <Button onClick={handleIncrement} variant="outline" size="icon" type="button">
          +
        </Button>
      </div>
    );
  });
  NumberInput.displayName = "NumberInput";