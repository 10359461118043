import React from 'react'
import { Container } from '../../components/ui'
import ReleasesTable from './ReleasesTable'

const ReleasesPage = () => {
  return (
    <Container type="flex" className="px-2 py-4">
    {/* Header */}
    <div className=' flex justify-between w-full items-center' >
        <h5 className='text-h5 font-bold flex-1'>Releases</h5>
     
    
    </div>
    {/* Member Content */}
    <ReleasesTable />
 

  </Container>
  )
}

export default ReleasesPage
