import React from 'react';

const EditIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className='text-[#A0A5AB] hover:text-blue'
    {...props}
  >
    <path
      d="M5 18.89H6.414L15.728 9.57598L14.314 8.16198L5 17.476V18.89ZM21 20.89H3V16.647L16.435 3.21198C16.6225 3.0245 16.8768 2.91919 17.142 2.91919C17.4072 2.91919 17.6615 3.0245 17.849 3.21198L20.678 6.04098C20.8655 6.2285 20.9708 6.48281 20.9708 6.74798C20.9708 7.01314 20.8655 7.26745 20.678 7.45498L9.243 18.89H21V20.89ZM15.728 6.74798L17.142 8.16198L18.556 6.74798L17.142 5.33398L15.728 6.74798Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
