import React from "react";

const ClubManagementIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.4998 10.6784V18.4736C17.4998 18.9338 17.1268 19.3069 16.6665 19.3069H3.33317C2.87294 19.3069 2.49984 18.9338 2.49984 18.4736V10.6784C1.9812 10.0908 1.6665 9.31893 1.6665 8.47359V3.47359C1.6665 3.01336 2.0396 2.64026 2.49984 2.64026H17.4998C17.9601 2.64026 18.3332 3.01336 18.3332 3.47359V8.47359C18.3332 9.31893 18.0185 10.0908 17.4998 10.6784ZM15.8332 11.7019C15.5668 11.7704 15.2876 11.8069 14.9998 11.8069C14.0043 11.8069 13.1106 11.3704 12.4998 10.6784C11.8891 11.3704 10.9954 11.8069 9.99984 11.8069C9.00425 11.8069 8.11062 11.3704 7.49984 10.6784C6.88905 11.3704 5.99541 11.8069 4.99984 11.8069C4.71209 11.8069 4.43285 11.7704 4.1665 11.7019V17.6403H15.8332V11.7019ZM11.6665 8.47359C11.6665 8.01336 12.0396 7.64026 12.4998 7.64026C12.9601 7.64026 13.3332 8.01336 13.3332 8.47359C13.3332 9.39409 14.0793 10.1403 14.9998 10.1403C15.9203 10.1403 16.6665 9.39409 16.6665 8.47359V4.30693H3.33317V8.47359C3.33317 9.39409 4.07936 10.1403 4.99984 10.1403C5.92031 10.1403 6.6665 9.39409 6.6665 8.47359C6.6665 8.01336 7.0396 7.64026 7.49984 7.64026C7.96007 7.64026 8.33317 8.01336 8.33317 8.47359C8.33317 9.39409 9.07934 10.1403 9.99984 10.1403C10.9203 10.1403 11.6665 9.39409 11.6665 8.47359Z'
      fill='currentColor'
    />
  </svg>
);

export default ClubManagementIcon;
