import React from "react";
import cn from "../../utils/cn";

// Select Component
export const Select = React.forwardRef(({ className,size="default", children, ...props }, ref) => {
  return (
    <select
      ref={ref}
      className={cn(
        `block h-fit ${size==="default" ? "min-h-[56px] text-base2 font-normal" : "min-h-[40px]  pr-6 pl-4 text-base2 font-medium"} text-dark w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-base2 text-secondary-greyfont-normal`,
        className
      )}
      {...props}
    >
      {children}
    </select>
  );
});
Select.displayName = "Select";