// src/layouts/AuthLayout.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import OnlinePlatform from "../assets/images/Online-Platform.png";
import Logo from "../assets/images/wineclub_logo_2.png";
import TextCarousel from "../components/carousels";
import useAuthStore from "../store/authStore";

const AuthLayout = () => {
  const { isAuthenticated } = useAuthStore();



  if (isAuthenticated ) {
    return <Navigate to='/' />;
  }

  return (
    <div className='flex min-h-screen  h-full items-stretch justify-between max-w-[2000px] mx-auto'>
      <div className='flex-1  bg-blue-bg flex flex-col items-center justify-start  '>
        <div className='flex h-32 items-center pl-14 pb-5 w-full'>
          <div className='w-[147px] h-[64px]'>
            <img className='w-full h-full object-contain' src={Logo} alt='' />
          </div>
        </div>

       <div className="flex flex-col  flex-1 justify-center ">
       <div className='flex justify-center select-none'>
          <img
            className='w-full max-w-[480px] h-auto '
            src={OnlinePlatform}
            alt='online platform'
          />
        </div>

          <TextCarousel />
       </div>
      </div>

      <div className='flex-1 my-auto  p-[40px]'>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
