import React from 'react'
import { Button, Container } from '../../../components/ui'
import TenantsTable from './TenantsTable'
import { TiPlus } from 'react-icons/ti'

const TenantsPage = () => {
  return (
    <Container type="flex" className="px-2 py-4">
      <div className='flex justify-between w-full items-center'>
      <h5 className='text-h5 font-bold flex-1'>Available Tenants</h5>

      <Button>
      <TiPlus />
       Add New Tenant
      </Button>

      </div>
          <TenantsTable />


    </Container>
  )
}

export default TenantsPage
