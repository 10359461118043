import React from "react";
import cn from "../../utils/cn";

export const TextArea = React.forwardRef(({ className, ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        
        className={cn(
          " h-[114px] w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-base2 placeholder:text-secondary-grey placeholder:font-normal",
          className
        )}
        {...props}
      />
    );
  });
  TextArea.displayName = "TextArea";