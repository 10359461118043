
const ReportsIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.8332 18.8069H4.1665C3.50346 18.8069 2.86758 18.5435 2.39874 18.0747C1.9299 17.6059 1.6665 16.97 1.6665 16.3069V2.97359C1.6665 2.75258 1.7543 2.54062 1.91058 2.38434C2.06686 2.22806 2.27882 2.14026 2.49984 2.14026H14.1665C14.3875 2.14026 14.5995 2.22806 14.7558 2.38434C14.912 2.54062 14.9998 2.75258 14.9998 2.97359V12.9736H18.3332V16.3069C18.3332 16.97 18.0698 17.6059 17.6009 18.0747C17.1321 18.5435 16.4962 18.8069 15.8332 18.8069ZM14.9998 14.6403V16.3069C14.9998 16.5279 15.0876 16.7399 15.2439 16.8962C15.4002 17.0525 15.6122 17.1403 15.8332 17.1403C16.0542 17.1403 16.2661 17.0525 16.4224 16.8962C16.5787 16.7399 16.6665 16.5279 16.6665 16.3069V14.6403H14.9998ZM13.3332 17.1403V3.80693H3.33317V16.3069C3.33317 16.5279 3.42097 16.7399 3.57725 16.8962C3.73353 17.0525 3.94549 17.1403 4.1665 17.1403H13.3332ZM4.99984 6.30693H11.6665V7.97359H4.99984V6.30693ZM4.99984 9.64026H11.6665V11.3069H4.99984V9.64026ZM4.99984 12.9736H9.1665V14.6403H4.99984V12.9736Z'
      fill='currentColor'
    />
  </svg>
);

export default ReportsIcon;
