import React from "react";
import cn from "../../utils/cn";

// Utility to join class names together

// Define variant and size styles
const buttonStyles = {
  base: "inline-flex  select-none  cursor-pointer items-center justify-center  rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  variants: {
    default: "bg-blue text-primary-foreground hover:bg-primary/90 text-white",
    destructive:
      "bg-destructive text-destructive-foreground hover:bg-destructive/90",
    outline:
      "outline-1 outline outline-[#DCDCDC] text-secondary-grey  bg-background hover:bg-accent ",
    noLeftRounded: "rounded-none rounded-r-md border border-[#DCDCDC]  h-[56px] w-[56px]",
    noRightRounded: "rounded-none rounded-l-md border border-[#DCDCDC] h-[56px] w-[56px]",
    secondary: "bg-red text-white hover:bg-secondary/80",
    ghost: "hover:bg-accent hover:text-accent-foreground",
    link: "text-primary underline-offset-4 hover:underline",
  },
  sizes: {
    default: "h-10 px-4 py-2",
    sm: "h-9 rounded-md ",
    lg: "h-11 rounded-md px-8",
    icon: "h-10 w-10",
    action: " px-4 py-2",
  },
};

// Button component
const Button = 
  (
    {
      className,
      variant = "default",
      size = "default",
      aschild = false,
      ...props
    }
  ) => {
    // Choose the appropriate component type (either a button or custom component)
    const Component = aschild ? "span" : "button"; // Use `span` for custom wrappers if aschild is true

    return (
      <Component
        className={cn(
          buttonStyles.base,
          buttonStyles.variants[variant],
          buttonStyles.sizes[size],
          className,
        )}
        {...props}
      />
    );
  }

Button.displayName = "Button";

export default Button;
