import React from "react";

const EyeIcon = (props) => (
  <svg
    width={props.width || "24"}
    height={props.height || "24"}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99911 2C11.5938 2 14.5844 4.58667 15.2118 8C14.5851 11.4133 11.5938 14 7.99911 14C4.40444 14 1.41378 11.4133 0.787109 8C1.41378 4.58667 4.40444 2 7.99911 2ZM7.99911 12.6667C9.35887 12.6665 10.6783 12.2048 11.7414 11.357C12.8045 10.5092 13.5484 9.32563 13.8511 8C13.5475 6.67521 12.8034 5.49264 11.7404 4.64569C10.6775 3.79875 9.35857 3.33757 7.99944 3.33757C6.64032 3.33757 5.32141 3.79875 4.25844 4.64569C3.19547 5.49264 2.45135 6.67521 2.14778 8C2.45051 9.32552 3.19423 10.509 4.25721 11.3568C5.32019 12.2045 6.63946 12.6664 7.99911 12.6667ZM7.99911 11C7.20346 11 6.4404 10.6839 5.87779 10.1213C5.31518 9.55871 4.99911 8.79565 4.99911 8C4.99911 7.20435 5.31518 6.44129 5.87779 5.87868C6.4404 5.31607 7.20346 5 7.99911 5C8.79476 5 9.55782 5.31607 10.1204 5.87868C10.683 6.44129 10.9991 7.20435 10.9991 8C10.9991 8.79565 10.683 9.55871 10.1204 10.1213C9.55782 10.6839 8.79476 11 7.99911 11ZM7.99911 9.66667C8.44114 9.66667 8.86506 9.49107 9.17762 9.17851C9.49018 8.86595 9.66578 8.44203 9.66578 8C9.66578 7.55797 9.49018 7.13405 9.17762 6.82149C8.86506 6.50893 8.44114 6.33333 7.99911 6.33333C7.55708 6.33333 7.13316 6.50893 6.8206 6.82149C6.50804 7.13405 6.33244 7.55797 6.33244 8C6.33244 8.44203 6.50804 8.86595 6.8206 9.17851C7.13316 9.49107 7.55708 9.66667 7.99911 9.66667Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeIcon;
