import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import api from '../api/axiosInstance';



/**
 * @typedef {Object} AuthState
 * @property {boolean} isAuthenticated - Whether the user is authenticated.
 * @property {string|null} token - The authentication token.
 * @property {Object|undefined} user - The user object.
 * @property {(data: { token: string, user: Object }) => void} login - Function to log in a user.
 * @property {() => void} logout - Function to log out the user.
 * @property {(user: Object) => void} setUser - Function to set the user object.
 * @property {(userData: Object) => void} updateUser - Function to update the user object.
 * @property {() => void} checkAuthStatus - Function to check the authentication status.
 */

/** @type {import('zustand').StateCreator<AuthState>} */

const useAuthStore = create(
  persist(
    (set, get) => ({
      isAuthenticated:  false,
      token: null,
      user: undefined,
      login: (data) => {
        set({ isAuthenticated: true, token: data.token, user: data.user});
        api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
      },
      logout: () => {
        set({ isAuthenticated: false, token: null, user: null  });
        delete api.defaults.headers['Authorization'];
      },
      setUser: (user) => {
        set({ user, isAuthenticated: true });
      },
      updateUser: (userData) => {
        set({ user: { ...get().user, ...userData } });
      },
      checkAuthStatus: () => {
        const token = get().token;

        if (token) {
          api.defaults.headers['Authorization'] = `Bearer ${token}`;
          set({ isAuthenticated: true  });
        }
        else {
          set({ isAuthenticated: false , token: null , user: null });
        }

      }

    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ token: state.token , isAuthenticated: state.isAuthenticated  }),
    }
  )
);

export default useAuthStore;

