import React from "react";

const ToggleSwitch = ({ name, checked, onChange }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="sr-only peer custom-switch appearance-none"
      />
      <div className="w-10 h-5 bg-[#3D55D726] 
       peer-focus:ring-2 peer-focus:ring-blue rounded-full peer   transition-all duration-200"></div>
      <div className="absolute top-[1px] left-[1px] w-[17.5px] h-[17.5px] bg-white peer-checked:bg-blue rounded-full transition-all  peer-checked:translate-x-5"></div>
    </label>
  );
};



export default ToggleSwitch;
