import React from "react";

const EmailCampaignsIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.5 2.97363C17.721 2.97363 17.933 3.06143 18.0893 3.21771C18.2455 3.37399 18.3333 3.58595 18.3333 3.80697V17.1461C18.3318 17.365 18.2442 17.5745 18.0896 17.7293C17.9349 17.8841 17.7255 17.9719 17.5067 17.9736H2.49333C2.27401 17.9734 2.06375 17.8861 1.90874 17.731C1.75373 17.5758 1.66667 17.3655 1.66667 17.1461V16.307H16.6667V6.55697L10 12.557L1.66667 5.05697V3.80697C1.66667 3.58595 1.75446 3.37399 1.91074 3.21771C2.06702 3.06143 2.27899 2.97363 2.5 2.97363H17.5ZM6.66667 12.9736V14.6403H0V12.9736H6.66667ZM4.16667 8.80697V10.4736H0V8.80697H4.16667ZM16.305 4.6403H3.695L10 10.3153L16.305 4.6403Z'
      fill='currentColor'
    />
  </svg>
);

export default EmailCampaignsIcon;
