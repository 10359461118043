import React from "react";

const EmailHistoryIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.3332 5.47367C18.3332 4.55701 17.5832 3.80701 16.6665 3.80701H3.33317C2.4165 3.80701 1.6665 4.55701 1.6665 5.47367V15.4737C1.6665 16.3903 2.4165 17.1403 3.33317 17.1403H16.6665C17.5832 17.1403 18.3332 16.3903 18.3332 15.4737V5.47367ZM16.6665 5.47367L9.99984 9.64034L3.33317 5.47367H16.6665ZM16.6665 15.4737H3.33317V7.14034L9.99984 11.307L16.6665 7.14034V15.4737Z'
      fill='currentColor'
    />
  </svg>
);

export default EmailHistoryIcon;
