import React from 'react'
import SendEmailForm from '../../components/forms/sendEmailForm'

const EmailCampaignsPage = () => {
  return (
    <div className="p-4">
    <SendEmailForm />
  </div>
  )
}

export default EmailCampaignsPage
