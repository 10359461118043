import React from 'react';
import { Tag } from '../../ui';
import { GoLocation } from "react-icons/go";

import upsImage from '../../../assets/images/upsImage.png';
import { EditIcon } from '../../../assets/icons';

const IntegrationsCard = ({ image, name, status, location ,licenceKey }) => {

  const statusMap = {
    Active: 'blue',
    Archive: 'warning',
    Disabled: 'warning',
  }

  return (
    <div className="flex flex-col gap-4   bg-white max-w-[361px]">
      {/* Logo */}
      <div className="flex justify-center rounded-md items-center h-[180px]  border border-gray-200">
        <img
          src={upsImage}
          alt={`${name} logo`}
          className="max-h-[100px] object-contain"
        />
      </div>

      {/* Details */}
      <div className="flex flex-col gap-1 px-2">
        <div>
        <Tag text={status} variant={statusMap[status]} sizes="sm" />

        </div>

        <p className="text-base font-medium flex w-full justify-between text-dark">{name} 
         <EditIcon className=" mr-3 text-[#A0A5AB] cursor-pointer hover:text-primary" /></p>
       {location &&
        <p className="  inline-flex gap-2 items-center">
        <GoLocation  /> 
        <span className='text-base2 text-secondary-grey font-medium'>
          {location}  
        </span>
        </p>
        }

        {licenceKey &&
        <p className="text-small text-secondary-grey font-normal">
         License Key: {licenceKey}
        </p>
        }
      </div>

      {/* Status */}
     
    </div>
  );
};

export default IntegrationsCard;
