import React from "react";
import { Bar } from "react-chartjs-2";
import MMRIcon from "../../assets/icons/MMRIcon.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Tag } from "../ui";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);



const RevenueChart = ({ data }) => {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 2500, // Sets increments of $2.5K for a total of 5 steps up to $10K
          callback: (value) => `$${(value / 1000).toFixed(1)}K`, // Format Y-axis labels as "$10K", "$7.5K", etc.
          color: "#000000", // Set text color to black
          font: {
            size: 12,
            family: "Inter", // Set font to Inter
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        border: {
          display: false,
        },
        ticks: {
          color: "#000000", // Set text color to black
          font: {
            size: 12,
            family: "Inter", // Set font to Inter
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw}`, // Tooltip format
        },
      },
    },
  };

  return (
    <div className='bg-white  h-full max-h-[420px] rounded-[5px] border flex flex-col justify-between '>
      <div className='flex text-center items-center justify-between border-b p-4'>
        <div className='flex text-center items-center'>
          <img src={MMRIcon} alt='' />
          <h4 className='text-dark font-bold text-[20px] ml-5'>Revenue</h4>
        </div>
        <div>
         <Tag variant="default" text={`${data.percentage}%`} />
        </div>
      </div>
      <div className="h-full max-h-[300px] pl-[16px] pr-[16px] pb-[16px]">
      <Bar data={data} className=' ' options={options}  />

      </div>
    </div>
  );
};

export default RevenueChart;
