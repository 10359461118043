import React from "react";
import cn from "../../utils/cn";
import { Button, Input } from "../ui/index"; // Make sure to import Button component

export const Iterator = React.forwardRef(({ className, handleDecrement, handleIncrement,name,id, value, ...props }, ref) => {

  


  return (
    <div
      ref={ref}
      className={cn(
        "h-[56px] flex w-full text-h6 border-[#DCDCDC] font-semibold text-secondary-grey select-none items-center justify-between rounded-md border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      <Button type="button" variant="noRightRounded" onClick={handleDecrement} className="btn">
        -
      </Button>
      <Input
        name= {name}
        id={id}
        value={value}
        readOnly
        className=" text-center outline-[#DCDCDC]   h-full min-h-[56px] max-w-[56px]  border-x-0 rounded-none focus:outline-none focus:ring-0 
        focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent focus-visible:ring-0 focus-visible:ring-offset-0
         no-shadow 
        "
        spacing = "iterator"
      />
      <Button type="button" variant="noLeftRounded" onClick={handleIncrement} className="btn">
        +
      </Button>
    </div>
  );
});

Iterator.displayName = "Iterator";