import React from "react";

const ChevronRightIcon = (props) => (
  <svg
    width={props.width || "8"}
    height={props.height || "12"}
    viewBox="0 0 8 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.17266 6.99996L0.222656 2.04996L1.63666 0.636963L8.00066 6.99996L1.63666 13.364L0.222656 11.949L5.17266 6.99996Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightIcon;
