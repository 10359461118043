const TenantsData = [
  {
    id: 1,
    name: "Tenant 1",
    email: "test@tenant1.com",
    type: "Winnery",
  },
  {
    id: 2,
    name: "Tenant 2",
    email: "test@tenant2.com ",
    type: "Winnery",
  },
  {
    id: 3,
    name: "Tenant 3",
    email: "test@tenant2.com",
    type: "Shop",
  },
];

export default TenantsData;
