import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label,} from "../../ui";
import RichTextEditor from "../../ui/MessageEditor";


const Step2CreateMessage = () => {
  const { values, setFieldValue } = useFormikContext();


  return (
    <div className="grid gap-[24px] w-full custom_body_input">
      {/* Select Audience */}
      <div className="flex flex-col gap-[6px]">
      <Label >Subject</Label>
      <Field name="subject" as={Input} placeholder="Enter subject" />
      <ErrorMessage
          name="subject"
          component="div"
          className="error-message text-red"
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="body">Body</Label>
        <RichTextEditor
          value={values.body}
          onChange={(content) => setFieldValue('body', content)}
        />
         <ErrorMessage
          name="body"
          component="div"
          className="error-message text-red"
        />
      </div>
          
    </div>
  );
};

// Validation schema for Step 1
export const Step2CreateMessageValidation = Yup.object().shape({
 subject: Yup.string().required("Subject is required"),
 body: Yup.string().required("Body is required"),
});


export default Step2CreateMessage;
