import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";
import {  Button, Container, Tag } from "../../components/ui";
import Table from "../../components/ui/table/Table";
import TableBody from "../../components/ui/table/TableBody";
import TableCell from "../../components/ui/table/TableCell";
import TableHeader from "../../components/ui/table/TableHeader";
import TableRow from "../../components/ui/table/TableRow";

const MembershipTable = () => {
  const columns = ['Member', 'Status', 'Card No.', 'Member No.', 'Member Since', 'Expires', 'Club Membership'];

  const data = [
    {
      name: 'Guy Hawkins',
      email:'jessica.hanson@example.com',
      status: 'Active',
      card: 'x-4242',
      memberNo: 16,
      memberSince: '17 Oct, 2020',
      expires: '22 Oct, 2020',
      club: 'SAVI Society Wine Club',
    },
    {
        name: 'Bessie Cooper',
        email:'deanna.curtis@example.com',

        status: 'Expired',
        card: 'x-4242',
        memberNo: 16,
        memberSince: '17 Oct, 2020',
        expires: '22 Oct, 2020',
        club: 'SAVI Society Wine Club',
        },
        {
        name: 'Courtney Henry',
        email:'willie.jennings@example.com',
        status: 'On Hold',
        card: 'x-4242',
        memberNo: 16,
        memberSince: '17 Oct, 2020',
        expires: '22 Oct, 2020',
        club: 'SAVI Society Wine Club',
    },
    {
        name: 'Guy Hawkins',
        email:'jessica.hanson@example.com',
        status: 'Active',
        card: 'x-4242',
        memberNo: 16,
        memberSince: '17 Oct, 2020',
        expires: '22 Oct, 2020',
        club: 'SAVI Society Wine Club',
      },
      {
          name: 'Bessie Cooper',
          email:'deanna.curtis@example.com',
  
          status: 'Expired',
          card: 'x-4242',
          memberNo: 16,
          memberSince: '17 Oct, 2020',
          expires: '22 Oct, 2020',
          club: 'SAVI Society Wine Club',
          },
          {
          name: 'Courtney Henry',
          email:'willie.jennings@example.com',
          status: 'On Hold',
          card: 'x-4242',
          memberNo: 16,
          memberSince: '17 Oct, 2020',
          expires: '22 Oct, 2020',
          club: 'SAVI Society Wine Club',
      }
    // Add more rows here
  ];


const actions = [
  {
    icon: <ChevronRightIcon width="12" height="16" />,
    onClick: (row) => console.log('View details', row),
    tooltip: 'View details',
  },
];

  return (
    <Container>
      <Table>
        <TableHeader columns={columns} hasActions={true}/>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {/* Member */}
              <TableCell>
                <div className="flex flex-col">
                  <span className="text-base2 font-medium">{row.name}</span>
                  <span className="text-small font-medium text-secondary-grey">{row.email}</span>
                </div>
              </TableCell>
              <TableCell>
                <Tag
                  variant={{
                    Active: 'default',
                    Expired: 'primary',
                    'On Hold': 'secondary',
                  }[row.status] || 'secondary'}
                  sizes="md"
                  text={row.status}
                />
              </TableCell>
              <TableCell>{row.card}</TableCell>
              <TableCell>{row.memberNo}</TableCell>
              <TableCell>{row.memberSince}</TableCell>
              <TableCell>{row.expires}</TableCell>
              <TableCell>{row.club}</TableCell>

              {/* Actions */}
              <TableCell className="flex justify-end  h-full">
                   <div className="flex justify-end items-center  w-full">
                   {actions.map((action, actionIndex) => (
                     <Button
                       key={actionIndex}
                       variant="ghost"
                       className=" text-center flex justify-center"
                       onClick={() => action.onClick(row)}
                       size="sm"
                     >
                      {action.icon}
                     </Button>
                   ))}
                 </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  )

};

export default MembershipTable;
