import React from 'react';

const TableRow = ({ children }) => {
  return    <tr className="border-t  text-dark text-base2 font-medium">
            {children}
            </tr>;
};

export default TableRow;

