
// This hook will fetch the integrations from the API and return them as an array of objects.
// The integrations are stored in the `integrations` key of the response object.
// This hook wil be also for the shipping carriers. ADD Update and Delete will be added in the future.

import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Dummyintegrations } from '../utils/data/integrations';

const useIntegrations = () => {

  const [isPaymentGateways, setIsPaymentGateways] = useState(true); // true for live, false for archive
  const tabs = [
    { label: 'Payment Gateways', value: true },  // true indicates "Payments Gateways"
    { label: 'Shipping Carriers', value: false }, // false indicates "Shipping Carriers"
  ];

  const { data } = useQuery({
    queryKey: ['integrationsKey'],
    refetchOnWindowFocus: false,
    staleTime: 60000, // Cache for 1 minute
    cacheTime: 5 * 60 * 1000, // Keep in cache for 5 minutes
    initialData: Dummyintegrations,
  });

  return { data, isPaymentGateways, setIsPaymentGateways, tabs };
}

export default useIntegrations;
