import React from "react";

const MembershipIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}

  >
    <path
      d='M1.6665 18.8069C1.6665 15.125 4.65127 12.1402 8.33317 12.1402C12.0151 12.1402 14.9998 15.125 14.9998 18.8069H13.3332C13.3332 16.0455 11.0946 13.8069 8.33317 13.8069C5.57175 13.8069 3.33317 16.0455 3.33317 18.8069H1.6665ZM8.33317 11.3069C5.57067 11.3069 3.33317 9.06938 3.33317 6.30688C3.33317 3.54438 5.57067 1.30688 8.33317 1.30688C11.0957 1.30688 13.3332 3.54438 13.3332 6.30688C13.3332 9.06938 11.0957 11.3069 8.33317 11.3069ZM8.33317 9.64022C10.1748 9.64022 11.6665 8.14855 11.6665 6.30688C11.6665 4.46522 10.1748 2.97355 8.33317 2.97355C6.4915 2.97355 4.99984 4.46522 4.99984 6.30688C4.99984 8.14855 6.4915 9.64022 8.33317 9.64022ZM15.2363 12.7259C17.5535 13.7703 19.1665 16.1002 19.1665 18.8069H17.4998C17.4998 16.7769 16.2901 15.0295 14.5522 14.2461L15.2363 12.7259ZM14.6633 3.31789C16.3285 4.00441 17.4998 5.64323 17.4998 7.55688C17.4998 9.94872 15.67 11.9112 13.3332 12.1216V10.4441C14.7471 10.2421 15.8332 9.02688 15.8332 7.55688C15.8332 6.40634 15.1678 5.41191 14.2007 4.93718L14.6633 3.31789Z'
      fill='currentColor'
    />
  </svg>
);

export default MembershipIcon;
