import React from "react";

const SettingsIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.23848 3.80712L9.41067 1.63495C9.73609 1.30951 10.2638 1.30951 10.5892 1.63495L12.7613 3.80712H15.8333C16.2935 3.80712 16.6666 4.18022 16.6666 4.64045V7.71236L18.8388 9.88455C19.1642 10.21 19.1642 10.7376 18.8388 11.0631L16.6666 13.2352V16.3071C16.6666 16.7674 16.2935 17.1405 15.8333 17.1405H12.7613L10.5892 19.3126C10.2638 19.6381 9.73609 19.6381 9.41067 19.3126L7.23848 17.1405H4.16657C3.70634 17.1405 3.33324 16.7674 3.33324 16.3071V13.2352L1.16107 11.0631C0.835632 10.7376 0.835632 10.21 1.16107 9.88455L3.33324 7.71236V4.64045C3.33324 4.18022 3.70634 3.80712 4.16657 3.80712H7.23848ZM4.99991 5.47378V8.40272L2.92884 10.4738L4.99991 12.5449V15.4738H7.92884L9.99992 17.5449L12.071 15.4738H14.9999V12.5449L17.071 10.4738L14.9999 8.40272V5.47378H12.071L9.99992 3.40272L7.92884 5.47378H4.99991ZM9.99992 13.8071C8.15896 13.8071 6.66657 12.3147 6.66657 10.4738C6.66657 8.63283 8.15896 7.14045 9.99992 7.14045C11.8408 7.14045 13.3333 8.63283 13.3333 10.4738C13.3333 12.3147 11.8408 13.8071 9.99992 13.8071ZM9.99992 12.1405C10.9204 12.1405 11.6666 11.3943 11.6666 10.4738C11.6666 9.5533 10.9204 8.80713 9.99992 8.80713C9.07942 8.80713 8.33326 9.5533 8.33326 10.4738C8.33326 11.3943 9.07942 12.1405 9.99992 12.1405Z'
      fill='currentColor'
    />
  </svg>
);

export default SettingsIcon;
