import React from "react";

const InventoryIcon = (props) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.3332 11.3069H16.6665V6.50517L10.0597 12.4219L3.33317 6.48686V16.3069H11.6665V17.9736H2.49984C2.0396 17.9736 1.6665 17.6005 1.6665 17.1402V3.80691C1.6665 3.34667 2.0396 2.97357 2.49984 2.97357H17.4998C17.9601 2.97357 18.3332 3.34667 18.3332 3.80691V11.3069ZM3.75939 4.64024L10.0514 10.1919L16.2507 4.64024H3.75939ZM17.4998 15.4736H19.9998V17.1402H17.4998V19.6402H15.8332V17.1402H13.3332V15.4736H15.8332V12.9736H17.4998V15.4736Z'
      fill='currentColor'
    />
  </svg>
);

export default InventoryIcon;
