import React from 'react';
import IntegrationsCard from '../../components/cards/IntegrationsCard';

const PaymentsCardList = ({ data }) => {
  if (!data) return <div>No Payments Found</div>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 mt-4 w-full max-w-[1400px]">
      {data.map((integration) => (
        <IntegrationsCard
          key={integration.id}
          image={integration.image}
          name={integration.name}
          status={integration.status}
          location={integration.location}
        />
      ))}
    </div>
  );
};

export default PaymentsCardList;
