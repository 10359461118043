import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";

function AppLayout() {
  return (
    <div className='flex h-screen'>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content Area */}
      <div className='flex-1 flex flex-col'>
        {/* Navbar */}
        <Navbar />

        {/* Outlet for nested routes */}
        <main className='px-0 pt-4 pb-0 flex-1 overflow-y-auto bg-white flex justify-between flex-col '>
          <Outlet />
          <Footer />
        </main>

        {/* Footer */}
      </div>
      
    </div>
  );
}

export default AppLayout;
