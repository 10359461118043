import React, {useState, useEffect, useRef} from "react";
import {Formik, Form} from "formik";
import Step1ClubInfo, {step1Validation} from "./Step1ClubInfo";
import Step2ConfigPricing, {step2Validation} from "./Step2ClubConfig";
// import Step3ShippingPerks, { step3Validation } from "./Step3ShippingPerks";
import {Button} from "../../ui";
import Step3ClubShipping, {step3Validation} from "./Step3ClubShipping";
import {ArrowBackIcon} from "../../../assets/icons";
import {Link, useNavigate} from "react-router-dom"; // Updated import
import ClubSteps from "../../../utils/steps/clubSteps";
import ProgressTracker from "../ProgressTracker";
import useNotification from "../../../hooks/useNotification";

const steps = [
  {component: Step1ClubInfo, validationSchema: step1Validation},
  {component: Step2ConfigPricing, validationSchema: step2Validation},
  {component: Step3ClubShipping, validationSchema: step3Validation},
];

const initialValues = {
  clubName: "",
  description: "",
  maxMembers: 1,
  clubType: "",
  billingType: "",
  allowPricingVisibility: false,
  totalBottles: 1,
  pricePerShipment: "",
  shipmentOption: "",
  taxRate: "",
  tastingRoomDiscount: 0,
  selectedShippingOptions: [], // Array of objects with selected options and their prices
  membershipPerks: "",
  boxWeight: "",
  boxHeight: "",
  boxLength: "",
  boxWidth: "",
  clubPicture: "", // Image file
};

function AddClubForm() {
  const navigate = useNavigate();
  const {showToast, STATUSES} = useNotification();

  const [step, setStep] = useState(0);
  const formikRef = useRef(null);

  const CurrentStepComponent = steps[step].component;

  const handleNext = (values, actions) => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      console.log("Form Submitted", values);

      // Simulate API request, then navigate to home
      setTimeout(() => {
        navigate("/"); // Redirect to home page
        showToast("Club Added Sucessfully", STATUSES.SUCCESS);

      }, 1000);

      // Uncomment when ready for API integration
      // api.submitForm(values).then(() => {
      //   navigate("/");
      // });

      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  // Trigger validation on step change
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm(); // Re-validate the form based on the new step's schema
      formikRef.current.setTouched({}); // Reset touched state to prevent errors from showing immediately
    }
    // Get all the values from the form
    const values = formikRef.current.values;
    console.log("Step Data:", values);
  }, [step]);

  return (
    <div className="max-w-[784px] w-full mx-auto flex flex-col justify-center gap-6">
      <div>
        <Link to="/club-management/clubs">
          <Button aschild variant="ghost" size="sm">
          <ArrowBackIcon className="text-black" />
          </Button>
        </Link>
        <h1 className="font-bold text-xl pt-2">Add New Club</h1>
      </div>

      <ProgressTracker currentStep={step} steps={ClubSteps} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={steps[step].validationSchema}
        onSubmit={handleNext}
      >
        {({isValid, values}) => (
          <Form className="flex flex-col gap-8">
            <CurrentStepComponent values={values} />
            <div className="flex justify-end gap-3 text-base2 font-medium">
              {step > 0 ? (
                <Button
                  variant="outline"
                  type="button"
                  onClick={handleBack}
                  className="outline-1"
                >
                  Back
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => {}}
                  className="btn"
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="default"
                type="submit"
                disabled={!isValid}
                className="btn btn-primary text-base2 font-medium "
              >
                {step === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddClubForm;
