import React from "react";
import {Button, Container, Input} from "../../../components/ui";
import {SearchIcon, TiPlus} from "../../../assets/icons";
import {Link} from "react-router-dom";
import RolesTable from "./RolesTable";

const RolesAndPermissionsPage = () => {
  return (
    <Container type="flex" className="px-2 py-4">
      <div className=" flex justify-between w-full items-center">
        <h5 className="text-h5 font-bold flex-1">Manage Memberships</h5>
        <div className="flex justify-end max-w-[550px] flex-1 gap-3 relative">
          <div className="inline-flex    focus-within:outline-primary80  w-full max-w-[444px] items-center justify-between outline-1 outline outline-[#DCDCDC]  rounded-md pr-4">
            <Input
              type="text"
              placeholder="Search"
              name="search"
              id="search"
              size="sm"
              className=" w-full  flex-1 border-none no-shadow placeholder:text-base placeholder:font-normal"
            />
            <SearchIcon className="h-6 w-6 text-gray-400 " />
          </div>
          <div className="inline-flex justify-center items-center  ">
            <Link to="add-role">
              <Button aschild variant="default" className={'text-nowrap'}>
                <TiPlus />
                Add Role
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <RolesTable />
    </Container>
  );
};

export default RolesAndPermissionsPage;
