// WineryPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

function WineryPage() {
  return (
    <>
      <Outlet /> 
    </>
  );
}

export default WineryPage;
