import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import useAuthStore from "./store/authStore";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import AppLayout from "./layouts/AppLayout";

// Pages
import DashboardPage from "./pages/dashboard";
import WineryPage from "./pages/club-management";
import ClubsPage from "./pages/club-management/clubs";
import AddClubPage from "./pages/club-management/add-club";
import InventoryPage from "./pages/club-management/inventory";
import MembershipsPage from "./pages/memberships";
import TenantsPage from "./pages/admin/tenants";
import RolesAndPermissionsPage from "./pages/admin/roles_and_premissions";
import AddRolePage from "./pages/admin/roles_and_premissions/add_role";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import IntergrationsPage from "./pages/integrations";
import ReleasesPage from "./pages/releases";
import EmailCampaignsPage from "./pages/email_campaigns";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/auth/login";
import { LoadingSpinner } from "./components/ui";
import AuthProvider from "./components/AuthProvider";
import { useAuth } from "./hooks/useAuth";

// Protected route components

function App() {
  const { user, isAuthenticated ,isLoading } = useAuth();



  return (
    <AuthProvider>
      <div className="main__container">

      { isLoading && <LoadingSpinner /> }

      <Toaster position="top-right"   />

      <Router>
        <Routes>
          <Route path='*' element={<h1>Not Found</h1>} />

          {/* Public Routes */}
          <Route element={<AuthLayout />}>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route path='/reset-password' element={<ResetPasswordPage />} />
          </Route>

          {/* Protected Routes */}
          <Route
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <AppLayout />
              </ProtectedRoute>
            }
          >
            <Route path='/' element={<DashboardPage user={user} />} />
            <Route path='/club-management' element={<WineryPage />}>
              <Route path='clubs' element={<ClubsPage />} />
              <Route path='clubs/add-club' element={<AddClubPage />} />
              <Route path='inventory' element={<InventoryPage />} />
            </Route>
            <Route path='/memberships' element={<MembershipsPage />} />
            <Route path='/integrations' element={<IntergrationsPage />} />
            <Route path='/releases' element={<ReleasesPage />} />
            <Route path='/send-email' element={<EmailCampaignsPage />} />

            {/*Super Admin-only routes */}
            <Route
              path='/admin'
              element={
                <AdminRoute isAdmin={user?.isAdmin || user?.id === 1}>
                  <Outlet /> {/* This will render the nested admin routes */}
                </AdminRoute>
              }
            >
              <Route index element={<Navigate to='roles' replace />} />{" "}
              {/* Redirect /admin to /admin/roles */}
              <Route path='roles' element={<RolesAndPermissionsPage />} />
              <Route path='roles/add-role' element={<AddRolePage />} />
              <Route path='tenants' element={<TenantsPage />} />
            </Route>
            {/* End of Super Admin-only routes */}
          </Route>
        </Routes>
      </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
