import modalStore from "../store/modalStore"

const useModals = () => {
  const { modals, openModal, closeModal, toggleModal } = modalStore()


  const isModalOpen = (modalId) => !!modals[modalId]

  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal,
  }
}

export default useModals

