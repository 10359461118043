import React from 'react';

const UploadIcon = (props) => (
  <svg
    width={props.width || "16"}
    height={props.height || "16"}
    viewBox="0 0 16 16"
    fill={props.fill || "none"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.30775 15.5C1.80258 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.1974 0.5 13.6922V11.7307C0.5 11.5179 0.571833 11.3397 0.7155 11.1962C0.859 11.0525 1.03717 10.9807 1.25 10.9807C1.46283 10.9807 1.641 11.0525 1.7845 11.1962C1.92817 11.3397 2 11.5179 2 11.7307V13.6922C2 13.7692 2.03208 13.8397 2.09625 13.9037C2.16025 13.9679 2.23075 14 2.30775 14H13.6923C13.7692 14 13.8398 13.9679 13.9038 13.9037C13.9679 13.8397 14 13.7692 14 13.6922V11.7307C14 11.5179 14.0718 11.3397 14.2155 11.1962C14.359 11.0525 14.5372 10.9807 14.75 10.9807C14.9628 10.9807 15.141 11.0525 15.2845 11.1962C15.4282 11.3397 15.5 11.5179 15.5 11.7307V13.6922C15.5 14.1974 15.325 14.625 14.975 14.975C14.625 15.325 14.1974 15.5 13.6923 15.5H2.30775ZM7.25 3.38845L5.327 5.31145C5.17817 5.46012 5.00158 5.53354 4.79725 5.5317C4.59275 5.5297 4.41292 5.45112 4.25775 5.29595C4.11292 5.14095 4.03792 4.96537 4.03275 4.7692C4.02758 4.57304 4.10258 4.39737 4.25775 4.2422L7.36725 1.1327C7.46092 1.03904 7.55967 0.973036 7.6635 0.934703C7.76733 0.896203 7.8795 0.876953 8 0.876953C8.1205 0.876953 8.23267 0.896203 8.3365 0.934703C8.44033 0.973036 8.53908 1.03904 8.63275 1.1327L11.7423 4.2422C11.8909 4.39087 11.9643 4.56495 11.9625 4.76445C11.9605 4.96379 11.8871 5.14095 11.7423 5.29595C11.5871 5.45112 11.4089 5.53129 11.2078 5.53645C11.0064 5.54162 10.8282 5.46662 10.673 5.31145L8.75 3.38845V11.0385C8.75 11.2513 8.67817 11.4295 8.5345 11.573C8.391 11.7166 8.21283 11.7885 8 11.7885C7.78717 11.7885 7.609 11.7166 7.4655 11.573C7.32183 11.4295 7.25 11.2513 7.25 11.0385V3.38845Z"
      fill={props.fill || "#666666"}
    />
  </svg>
);

export default UploadIcon;