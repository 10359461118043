const RolesData = [
  {
    id: 1,
    name: 'Admin',
    description: 'Admin role'
  },
  {
    id: 2,
    name: 'Manager',
    description: 'Manager role'
  },
  {
    id: 3,
    name: 'Guest',
    description: 'Guest role'
  }
]

export default RolesData;