import React, { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Input, Label, Select, Checkbox, Iterator, TextArea, Button, Container } from "../../ui";
import * as Yup from "yup";
import { UploadIcon } from "../../../assets/icons";

const Step3ClubShipping = () => {




  const { values, setFieldValue } = useFormikContext();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setFieldValue('clubPicture', file);
    }
    event.target.value = null;
  };


  const shippingOptions = [
    "Manual",  
    "UPS Ground",
    "UPS 3 Day Select",
    "UPS 2nd Day Air",
    "UPS Next Day Air Early AM",
    "UPS Next Day Air",
  ];

  const handleCheckboxChange = (option) => {
    const selectedIndex = values.selectedShippingOptions.findIndex(item => item.name === option);
  
    if (selectedIndex > -1) {
      // Option is already selected, remove it
      const updatedOptions = values.selectedShippingOptions.filter(item => item.name !== option);
      setFieldValue("selectedShippingOptions", updatedOptions);
    } else {
      // Option is not selected, add it with a default empty price
      const updatedOptions = [
        ...values.selectedShippingOptions,
        { name: option, price: "" }
      ];
      setFieldValue("selectedShippingOptions", updatedOptions);
    }
  };

  return (
    <div className="flex flex-col  justify-start gap-6" >
      <h2 className="text-h6 font-bold  text-dark ">Club Membership Perks</h2>

      {/* Tasting Room Discount */}
      <div className='flex flex-col items-start justify-between  gap-2 w-fit'>
        <Label htmlFor='tastingRoomDiscount'>Tasting Room Discount</Label>
        <div className="flex items-center  h-full">
        <Iterator
          name='tastingRoomDiscount'
          id='tastingRoomDiscount'
          value={`${values.tastingRoomDiscount}%`}
          handleIncrement={() => setFieldValue('tastingRoomDiscount', values.tastingRoomDiscount + 1)}
          handleDecrement={() =>  values.tastingRoomDiscount>0 &&  setFieldValue('tastingRoomDiscount', values.tastingRoomDiscount - 1)}
          className=""
        />
        </div>
      
      </div>

      {/* Other Perks */}
      <div className="">
        <Label htmlFor="membershipPerks">Write Any Other Perks Included *</Label>
        <Field
          name="membershipPerks"
          as={TextArea}
          placeholder="Write perks here"
          className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
        />
        <ErrorMessage name="membershipPerks" component="div" className="error-message text-red" />
      </div>

      {/* Shipping Options */}
     <Container  className="gap-6" >
      <h2 className="text-h6 font-bold text-dark mt-2 pt-6 border-t">Shipping</h2>
      <div className="flex   justify-start items-start gap-6">
        <div className="flex-1 flex flex-col justify-start h-full">
          <Label htmlFor="isShippable">Is this Club Shippable or Pickup Only *</Label>
          <Field
            name="isShippable"
            as={Select}
            className="block w-full mt-2 p-2   border border-gray-300 rounded-md"
          >
            <option value="">Choose an option</option>
            <option value="shippable">Shippable</option>
            <option value="pickupOnly">Pickup Only</option>
          </Field>
          <ErrorMessage name="isShippable" component="div" className="error-message text-red" />
        </div>

        <div className="flex-1   h-full flex flex-col justify-start">
          <Label htmlFor="shippingType">Select a Shipping Type *</Label>
          <Field
            name="shippingType"
            as={Select}
            className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
          >
            <option value="">Choose a shipping type</option>
            <option value="ground">Ground</option>
            <option value="air">Air</option>
          </Field>
          <ErrorMessage name="shippingType" component="div" className="error-message text-red" />
        </div>
      </div>

      <Container className="gap-6 justify-start p-4 bg-[#FCFCFC] border border-gray-300 rounded-md " >
 
      <div className="grid grid-cols-1 gap-4  ">
      <div className="text-body font-bold gap-6 text-dark inline-flex w-full justify-start ">
          <h2 className="flex-1 pr-4">Shipping Options</h2> <h2 className="flex-1 px-2">Price</h2>
        </div>
        {shippingOptions.map((option) => {
          const isChecked = values.selectedShippingOptions.some(item => item.name === option);
          return (
            <div key={option} className="flex items-center gap-6 ">
              <div className="flex items-center  text-dark flex-1 gap-4 p-4 border rounded-md  h-[56px]">
              <Checkbox
                type="checkbox"
                checked={isChecked}
                onChange={() => handleCheckboxChange(option)}
              />
              <span  className="text-dark text-base font-medium">{option}</span>
              </div>
              <Field
                name={`price_${option}`}
                as="input"
                type="number"
                placeholder=" $     Enter Price"
                value={
                  values.selectedShippingOptions.find(item => item.name === option)?.price || ""
                }
                onChange={(e) => {
                  const updatedOptions = values.selectedShippingOptions.map(item =>
                    item.name === option ? { ...item, price: e.target.value } : item
                  );
                  setFieldValue("selectedShippingOptions", updatedOptions);
                }}
                disabled={!isChecked} // Disable input if checkbox is not checked
                className="block p-2 border border-gray-300 rounded-md flex-1 h-full placeholder:text-base2 placeholder:font-normal"
              />
              <ErrorMessage name={`price_${option}`} component="div" className="error-message text-red" />
            </div>
          );
        })}
      </div>
        </Container>
      

     </Container>
      {/* Additional Options */}
      <div className="space-y-4 ">
        <div className="flex items-start">
          <Field name="applyFreeShipping" type="checkbox" as={Checkbox} />
          <Label className="ml-2">Apply Free Shipping to 7 Day Shipments</Label>
        </div>

        <div className="flex items-start">
          <Field name="applyShippingToState" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
            Apply free shipping to members in your state/province (SC)?
          </Label>
        </div>
      </div>

      {/* Shipping Box Dimensions */}
      <h2 className="text-base2 font-medium">Shipping Box Dimensions</h2>
      <div className="flex gap-6">
        <div className="">
          <Label htmlFor="boxWidth">Width *</Label>
          <Field
            name="boxWidth"
            as={Input}
            type="number"
            placeholder="--                   Inches"
            className="block w-full p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxWidth" component="div" className="error-message text-red" />
        </div>

        <div>
          <Label htmlFor="boxLength">Length *</Label>
          <Field
            name="boxLength"
            as={Input}
            type="number"
            placeholder="--                  Inches"
            className="block w-full p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxLength" component="div" className="error-message text-red" />
        </div>

        <div>
          <Label htmlFor="boxHeight">Height *</Label>
          <Field
            name="boxHeight"
            as={Input}
            type="number"
            placeholder="--                  Inches"
            className="block w-full p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxHeight" component="div" className="error-message text-red" />
        </div>

        <div>
          <Label htmlFor="boxWeight">Weight *</Label>
          <Field
            name="boxWeight"
            as={Input}
            type="number"
            placeholder="--                 Pounds"
            className="block w-full p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxWeight" component="div" className="error-message text-red" />
        </div>
      </div>

      {/* Club Picture Upload */}
      <div className="mb-6">
      <Label htmlFor="clubPicture">Club Picture</Label>
      <div className="mt-2 border border-dashed border-gray-300 p-4 rounded-md text-center">
        {selectedFile ? (
          <div className="flex flex-col items-center">
            <img src={selectedFile} alt="Selected" className="max-w-[200px] h-auto rounded-md" />
            <Button
            variant="secondary"
              onClick={() => {
                setSelectedFile(null);
                setFieldValue('clubPicture', '');
              }}
              className="mt-2 text-red-500 hover:text-red-700"
            >
              Remove
            </Button>
          </div>
        ) : (
          <Label
            htmlFor="clubPicture"
            className="inline-flex justify-center w-full p-2 font-normal text-base2 rounded-md cursor-pointer gap-3"
          >
            <UploadIcon /> Drag & Drop or click here to upload club picture
          </Label>
        )}
        <Input
          type="file"
          id="clubPicture"
          name="clubPicture"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
    </div>

      {/* Additional Checkboxes */}
      <div className="space-y-4 ">
        <div className="flex items-start">
          <Field name="markAsPrivate" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
           Mark this club as private (It won’t be visible from the front-end clubs page)
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="clubVisible" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
            Club Visible (If unchecked, then no one can sign up for this club)
          </Label>
        </div>
      </div>
    </div>
  );
};

export const step3Validation = Yup.object().shape({
  isShippable: Yup.string().required("Please choose an option"),
  shippingType: Yup.string().required("Please select a shipping type"),
  boxWidth: Yup.number().required("Width is required"),
  boxLength: Yup.number().required("Length is required"),
  boxHeight: Yup.number().required("Height is required"),
  boxWeight: Yup.number().required("Weight is required"),
});

export default Step3ClubShipping;
