// api/userQueries.js
import {useQuery} from "@tanstack/react-query";
import api from "../axiosInstance";


export const getUserData = async () => {
  try {
    const response = await api.get("/user");
    return response.data; // Return the actual data
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
};
 

// Fetch clubs data (only if the user is authenticated)
export const useClubsDataQuery = (isAuthenticated) => {
  return useQuery({
    queryKey: ["clubsData"],
    queryFn: () => api.get("/clubs").then((res) => res.data),
    enabled: isAuthenticated, // Only fetch if the user is authenticated
    staleTime: 60000, // Cache for 1 minute
    cacheTime: 5 * 60 * 1000, // Keep in cache for 5 minutes
  });
};
